import React from "react";

export interface HighlightedTextProps {
    input?: string;
    highlighted: string;
}

function HighlightedText(props: HighlightedTextProps): JSX.Element {
    if (!props.input) {
        return <></>; // Empty string or none given
    }

    const regex = new RegExp(`${props.highlighted}`, "i");
    const parts = props.input.split(regex);

    const elements: (string | JSX.Element)[] = [parts[0]];
    for (let index = 1; index < parts.length; index++) {
        elements.push(<span key={parts[index]}>{props.highlighted}</span>);
        elements.push(parts[index]);
    }

    return <>{elements}</>;
}

export default React.memo(HighlightedText);
