export const customScrollBar = {
    "&::-webkit-scrollbar": {
        width: 5,
        height: 5,
        backgroundColor: "#f5f5f5",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.6)",
        borderRadius: "5px",
        opacity: 0.4,
        "&:hover": {
            opacity: 1,
        },
    },
};
