import { FormControl, InputBase, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomFormControl = styled(FormControl)({
    marginLeft: "0.5rem",
    minWidth: 100,
});

export const CustomSelect = styled(Select)({
    "& > *": {
        fontFamily: "ArtifaktElement,sans-serif",
    },
});

export const CustomSelectInput = styled(InputBase)(({ theme }) => ({
    fontFamily: "ArtifaktElement,sans-serif",
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        fontSize: 14,
        fontFamily: "ArtifaktElement,sans-serif",
        fontWeight: "600",
        backgroundColor: "#eee",
        borderRadius: 40,
        position: "relative",
        padding: "3px 26px 3px 12px",
        "&:focus": {
            borderRadius: 40,
        },
    },
}));

export const CustomMenuItem = styled(MenuItem)({
    fontFamily: "ArtifaktElement,sans-serif",
    fontSize: 14,
});
