import { Location } from "react-router-dom";

export function extractSiteName(search: string): string {
    if (!search || search === "") {
        return "";
    }

    // Replace ':' and '-' with spaces, keep '.' characters.
    return search.replace(/[:-]/g, " ");
}

export function getPageNameFromLocation(location: Location): string {
    const pathname = location.pathname;
    const pageNameParts: string[] = [];

    if (pathname === "/home") {
        pageNameParts.push("home");
    } else if (pathname.startsWith("/schema/")) {
        const schemaName = pathname.substring("/schema/".length);

        pageNameParts.push("schema");
        pageNameParts.push(extractSiteName(schemaName));
    } else if (pathname === "/") {
        pageNameParts.push("landing");
    } else {
        // Handle unknown future page names
        pageNameParts.push("others");
        if (pathname && pathname.length > 0) {
            pageNameParts.push(pathname.replace(/[/:-]/g, " "));
        }
    }

    // Make sure the name parts do not have starting/trailing whitespaces.
    return `adsk:schema:${pageNameParts.map((s) => s.trim()).join(":")}`;
}
