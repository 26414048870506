import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getPageNameFromLocation } from "../utils/getPageNameFromLocation";

declare global {
    interface Window {
        QSI?: {
            API: {
                unload: () => void;
                load: () => void;
                run: () => void;
            };
            isDebug: boolean;
        };
        __analyticsChangeContext: Record<string, unknown>[];
    }
}

export default function QualtricsMiddleware(): JSX.Element {
    const location = useLocation();

    async function loadQualtrics() {
        if (typeof window.QSI !== "undefined") {
            window.QSI.API.unload();
            window.QSI.API.load();
            window.QSI.API.run();
        } else {
            const checkQualtrics = setTimeout(function () {
                loadQualtrics();
                clearTimeout(checkQualtrics);
            }, 3000); // delay 3 sec
        }
    }

    async function logPageView() {
        const pageName = getPageNameFromLocation(location);
        window.__analyticsChangeContext = window.__analyticsChangeContext || [];
        window.__analyticsChangeContext.push({
            eventData: {
                eventName: "new_page_view",
                values: { pageName },
            },
        });
    }

    React.useEffect(() => {
        loadQualtrics();
        logPageView();
    }, [location]); // Whenever location changes

    return <Outlet />;
}
