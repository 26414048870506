/**
 * Customised fetch with caching to session storage
 * @param url string
 * @param options request init
 * @returns Promise
 */
export default async function fetchCached(
    url: string,
    options?: RequestInit,
    cacheResponse?: boolean
): Promise<Response> {
    const cacheKey = url; // URL stored as key
    const cached = sessionStorage.getItem(cacheKey);
    if (cached !== null) {
        // session storage found
        if (cacheResponse) {
            // Return cached response
            const response = new Response(new Blob([cached]));
            return Promise.resolve(response);
        } else {
            // Removed cached response
            sessionStorage.removeItem(cacheKey);
        }
    }

    // Fetch response if storage not found
    return fetch(url, options).then((response) => {
        if (response.status === 200) {
            const ct = response.headers.get("Content-Type");
            // Cache response, but restricts to json and text only
            if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
                response
                    .clone()
                    .text()
                    .then((content) => {
                        sessionStorage.setItem(cacheKey, content);
                    });
            }
        }
        return response;
    });
}
