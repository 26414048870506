import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import { callPostApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { UserProfile } from "../types/UserProfile";

export interface UserProfiles {
    [userId: string]: UserProfile;
}

interface UserIdOrEmail {
    userId?: string;
    emailId?: string;
}

async function fetchAndUnpackProfiles(token: string, userIdsOrEmails: string[]): Promise<UserProfiles> {
    if (!userIdsOrEmails || userIdsOrEmails.length === 0) {
        return {}; // No input, return an empty object by design
    }

    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const payload: UserIdOrEmail[] = userIdsOrEmails.map((x) => {
        return x.match(emailRegex) ? { emailId: x } : { userId: x };
    });

    const path = "/proxy/user-profiles:batch-get";
    const result = await callPostApi<UserIdOrEmail[], UserProfile[]>(path, token, payload);

    /**
     * Transform the response in this format:
     *
     *      response = [
     *          {
     *              "userId": "GCZLMWB8B5NG",
     *              "userName": "junxiang.zheng",
     *              "emailId": "junxiang.zheng@autodesk.com",
     *          }
     *      ]
     *
     * ... into this format:
     *
     *      userProfiles = {
     *          "GCZLMWB8B5NG": {
     *              "userId": "GCZLMWB8B5NG",
     *              "userName": "junxiang.zheng",
     *              "emailId": "junxiang.zheng@autodesk.com",
     *          }
     *      }
     *
     * This is so that consumers of 'useUserProfiles' can simply perform an
     * indexing into the output with a user's ID, instead of having to loop
     * through the output array (making code easier to read).
     */
    const userProfiles: UserProfiles = {};
    result.forEach((res) => (userProfiles[res.userId] = res));
    return userProfiles;
}

export default function useUserProfiles(userIdsOrEmails: string[]): QueryHookOutput<UserProfiles> {
    const token = useAppSelector((state) => state.auth.token);
    const queryKey = [...userIdsOrEmails].sort().join("-");

    return useQuery({
        staleTime: Infinity, // User profiles never change
        enabled: !!token,
        queryKey: [`user-profiles-${queryKey ? queryKey : "none"}`],
        queryFn: async () => fetchAndUnpackProfiles(token, userIdsOrEmails),
    });
}
