import GridViewIcon from "@mui/icons-material/GridView";
import { styled, Typography } from "@mui/material";

export const CustomTypography = styled(Typography)({
    fontFamily: "ArtifaktElement,sans-serif",
    fontSize: "12px",
    color: "#212121",
    paddingLeft: "0.5rem",
});

export const CustomGridViewIcon = styled(GridViewIcon)({
    fontSize: "12px",
    marginLeft: "3rem",
});
