import { Box, styled } from "@mui/material";
import { customScrollBar } from "../../themes/mixins";

// Entire page wrapper
// Contains Navbar, AlertBanner, ContentWrapper
export const LayoutWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100%", // Fill the entire screen
}) as typeof Box;

// Navbar at the top
export const LayoutNavbar = styled(Box)({
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
}) as typeof Box;

// Banner at the top, below Navbar
export const LayoutAlertBanner = styled(Box)({
    display: "block",
}) as typeof Box;

// Entire content wrapper
// Contains ContentLeftWrapper and ContentMainWrapper
export const LayoutContentWrapper = styled(Box)({
    display: "flex",
    flex: 1,
    overflow: "hidden",
    "@media (max-width: 768px)": {
        display: "none", // Hide if screen is 768px or lesser
    },
}) as typeof Box;

// Entire left wrapper
// Contains ContentLeft and optionally Footer
export const LayoutContentLeftWrapper = styled(Box)({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    flex: "0 1 auto",
    borderRight: "1px solid #f1f1f1",
    // Display in column when within Left Panel
    "& #fsr-footer-content": {
        fontSize: "10px",
        padding: "1rem",
        textAlign: "left",
        backgroundColor: "#fff",
        "&>p": {
            fontSize: "10px",
            display: "block",
            padding: "3px 0",
            margin: 0,
        },
        "&>a": {
            fontSize: "10px",
            display: "block",
            padding: "3px 0",
            margin: 0,
        },
        "&>span": {
            display: "none",
        },
    },
}) as typeof Box;

export const LayoutContentLeft = styled(Box)({
    flex: 1,
    overflow: "hidden",
    display: "flex",
}) as typeof Box;

// Entire main wrapper
// Contains Content Main, Breadcrumb and Footer
export const LayoutContentMainWrapper = styled(Box)({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#fff",
}) as typeof Box;

export const LayoutContentBreadcrumb = styled(Box)({
    display: "block",
}) as typeof Box;

// Contains Content Middle, Content Right and Toolbar
export const LayoutContentMain = styled(Box)({
    display: "flex",
    flex: 1,
    overflow: "hidden",
}) as typeof Box;

export const LayoutContentMiddle = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    ...customScrollBar,
    // To support single-column view with long content
    "&.fsr-layout-single-column": {
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
    },
}) as typeof Box;

export const LayoutContentRight = styled(Box)({
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    overflow: "hidden",
    borderRight: "1px solid #f1f1f1",
}) as typeof Box;

export const LayoutContentRightScrollable = styled(Box)({
    position: "relative",
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    ...customScrollBar,
}) as typeof Box;

export const LayoutContentToolbar = styled(Box)({
    display: "flex",
}) as typeof Box;

export const LayoutContentFooter = styled(Box)({
    width: "100%",
}) as typeof Box;

// If screen resolution is not supported
export const NoSupportWrapper = styled(Box)({
    display: "none",
    flexDirection: "column",
    height: "100%",
    "@media (max-width: 768px)": {
        display: "flex", // Show if screen is 768px or lesser
        flex: 1,
        padding: 20,
    },
}) as typeof Box;

export const SinglePanelCenter = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "70px",
});
