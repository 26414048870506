import { useQuery } from "@tanstack/react-query";
import React from "react";
import { ApiResponse, QueryHookOutput } from "./interfaces/DataHookOutput";
import fetchPaginated from "./utils/fetchPaginated";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { AppContext, ApplicationContext } from "../ApplicationContext";
import { useAppSelector } from "../redux/hooks";
import { Organization } from "../types/Organization";

export default function useOrganizations(offset?: number, limit?: number): QueryHookOutput<Organization[]> {
    const token = useAppSelector((state) => state.auth.token);
    const appContext = React.useContext<AppContext>(ApplicationContext);

    [offset, limit] = validateOffsetLimit(offset, limit);

    const path = `/organizations`;

    return useQuery({
        enabled: !!token,
        queryKey: ["all-organizations"],
        queryFn: async () => {
            let response = await fetchPaginated<Organization, ApiResponse<Organization[]>>(path, token, offset, limit);
            if (appContext.env.REACT_APP_ENV === "prod") {
                // On PROD site we only want 'autodesk' and 'aecom' organizations displayed
                response = response.filter((o) => o.name === "autodesk" || o.name === "aecom");
            }

            return response;
        },
    });
}
