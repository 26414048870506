import { ListItemButton } from "@mui/material";
import React from "react";
import { CustomGridViewIcon, CustomTypography } from "./stylesheet";
import { StringVoidFunc } from "../../types/Functions";

export interface GlobalSearchResultTagProps {
    tagId: string;
    onTagLinkClicked: StringVoidFunc;
}

export default function GlobalSearchResultTag(props: GlobalSearchResultTagProps): JSX.Element {
    return (
        <ListItemButton onClick={() => props.onTagLinkClicked(props.tagId)} data-testid="fsr-global-searchresult-item">
            <CustomGridViewIcon />
            <CustomTypography>{props.tagId}</CustomTypography>
        </ListItemButton>
    );
}
