import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import common_en from "./locales/en/common.json";
import common_zh from "./locales/zh/common.json";

export const defaultNS = "common";
export const resources = {
    en: {
        common: common_en,
    },
    zh: {
        common: common_zh,
    },
} as const;

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        ns: ["common"],
        defaultNS,
        resources,
    });

export default i18n;
