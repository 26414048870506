export const buttonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
};

export const signInTextStyle = {
    color: "#ffffff",
    lineHeight: 1,
    fontSize: 14,
    marginLeft: "7px",
    whiteSpace: "nowrap",
};

export const secondaryNavStyle = {
    background: "#0a0a0a",
    borderBottom: "1px solid #3d3d3d",
    zIndex: 2,
    "& .MuiToolbar-root": {
        minHeight: "30px",
    },
};
