import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { callPatchApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { ACMPermissionRequestData } from "../types/ACMPermission";

export default function useAddLibraryPermission(
    organizationId?: string,
    groupId?: string,
    libraryId?: string
): UseMutationResult {
    const token = useAppSelector((state) => state.auth.token);

    return useMutation((formData) => {
        const requestBody = formData as ACMPermissionRequestData;
        return callPatchApi(
            `/organizations/${organizationId}/groups/${groupId}/libraries/${libraryId}`,
            token,
            requestBody
        );
    });
}
