import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { TokenData } from "../types/AuthResponse";
import { UserProfile } from "../types/UserProfile";

// Define a type for the slice state
export interface AuthState {
    isLogin: boolean;
    token: string;
    refreshToken: string;
    expiresInMs: number;
    tokenTimeMs: number;
    profile: UserProfile | null;
    isInternal: boolean;
}

// Define the initial state using that type
export const initialState: AuthState = {
    isLogin: false,
    token: "",
    refreshToken: "",
    expiresInMs: 0,
    tokenTimeMs: Date.now(),
    profile: null,
    isInternal: false,
};

function setAuthState(state: Draft<AuthState>, action?: PayloadAction<TokenData>): void {
    state.token = action?.payload.access_token ?? "";
    state.refreshToken = action?.payload.refresh_token ?? "";
    state.expiresInMs = (action?.payload.expires_in ?? 0) * 1000;
    state.tokenTimeMs = action?.payload.token_time ?? Date.now();
    state.isLogin = !!action;

    // Leave profile untouched when signed-in or
    // refreshed token, otherwise clear the profile data.
    state.profile = action ? state.profile : null;
    state.isInternal = action ? state.isInternal : false;
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signIn: (state, action: PayloadAction<TokenData>) => setAuthState(state, action),
        signOut: (state) => setAuthState(state),
        refreshToken: (state, action: PayloadAction<TokenData>) => setAuthState(state, action),
        setProfile: (state, action: PayloadAction<UserProfile>) => {
            state.profile = action.payload;
            state.isInternal = /@autodesk.com$/.test(action.payload.emailId ?? "");
        },
    },
});

export const { signIn, signOut, setProfile, refreshToken } = authSlice.actions;

export default authSlice.reducer;
