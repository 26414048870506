import lightThemeData from "@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const primitives = lightThemeData.primitives;

export const SplitterContentWrapper = styled(Box)({
    display: "flex",
    height: "100%",
    position: "relative",
});

export const SplitterBox = styled(Box)({
    position: "absolute",
    width: "8px", // Increase hit-test area
    height: "100%",
    top: 0,
    cursor: "ew-resize",
    zIndex: 10,
    backgroundColor: "transparent",
    transition: "background-color 100ms linear",
    "&.right-splitter": {
        right: -1,
    },
    "&.right-splitter:hover": {
        borderRight: `2px solid ${primitives.colors.accent.iris}`,
    },
    "&.left-splitter": {
        left: 0,
    },
    "&.left-splitter:hover": {
        borderLeft: `2px solid ${primitives.colors.accent.iris}`,
    },
}) as typeof Box;
