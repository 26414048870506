export default function validateOffsetLimit(offset?: number, limit?: number): [offset: number, limit: number] {
    if (typeof offset === "number" && offset < 0) {
        throw new Error(`Invalid 'offset' value specified: ${offset}`);
    }

    if (typeof limit === "number" && limit <= 0) {
        throw new Error(`Invalid 'limit' value specified: ${limit}`);
    }

    // If 'offset' is undefined, set it to 0;
    // otherwise, take the value as specified.
    offset = !offset ? 0 : offset;

    // If 'limit' is undefined, set it to large number.
    limit = !limit ? 500 : limit;

    return [offset, limit];
}
