import ThemeMui from "@digital-hig/theme-mui";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { Cookies } from "react-cookie";

import {
    bodyStyle,
    linkStyle,
    menuListStyle,
    profileAvatarStyle,
    profileBtnStyle,
    profileIdStyle,
    profileInfoStyle,
    titleStyle,
} from "./stylesheet";

import { AppContext, ApplicationContext } from "../../../ApplicationContext";
import AuthRequest from "../../../models/AuthRequest";
import { setProfile } from "../../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

export default function ProfileFlyout(): JSX.Element {
    const appContext = React.useContext<AppContext>(ApplicationContext);

    const dispatch = useAppDispatch();
    const isLogin = useAppSelector((state) => state.auth.isLogin);
    const token = useAppSelector((state) => state.auth.token);
    const profile = useAppSelector((state) => state.auth.profile);
    const [logoutLink, setLogoutLink] = React.useState<string>("");
    const [fullName, setFullName] = React.useState<string>("");

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // Run on load
    React.useEffect(() => {
        let isStillMounted = true;
        (async () => {
            const auth = new AuthRequest();
            const logoutUrl = await auth.logoutUrl();
            if (isStillMounted) {
                setLogoutLink(logoutUrl);
            }
        })();

        return () => {
            isStillMounted = false;
        };
    }, []);

    React.useEffect(() => {
        let isStillMounted = true;
        (async () => {
            if (isLogin && token) {
                const auth = new AuthRequest();
                const profile = await auth.getProfile(token);
                if (isStillMounted && profile && profile.userId) {
                    dispatch(setProfile(profile));
                }
            }
        })();

        return () => {
            isStillMounted = false;
        };
    }, [isLogin, token]);

    // Set cookie for Feedback plugin
    React.useEffect(() => {
        let isStillMounted = true;
        const cookies = new Cookies();
        const sso = cookies.get("identity-sso");
        if (profile && profile.userId && !sso) {
            cookies.set("identity-sso", profile.userId, {
                secure: appContext.env.REACT_APP_ENV !== "local",
                sameSite: appContext.env.REACT_APP_ENV === "local" ? "none" : "lax",
            });
        }
        if (isStillMounted && profile) {
            setFullName(`${profile?.firstName || ""} ${profile?.lastName || ""}`);
        }

        return () => {
            isStillMounted = false;
        };
    }, [profile]);

    return (
        <ThemeMui baseThemeName="dhig2021Dark" themePrefix="dhig2021Dark">
            <IconButton onClick={handleOpenUserMenu} sx={profileBtnStyle}>
                <Avatar alt={fullName} sx={profileAvatarStyle}>
                    {profile?.firstName?.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>
            <Menu
                sx={{ mt: "35px" }}
                id="menu-appbar-profile"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                MenuListProps={menuListStyle}
            >
                <Typography component="li" sx={profileInfoStyle}>
                    <Typography data-testid="fsr-profile-fullname" component="span" sx={titleStyle}>
                        {fullName}
                    </Typography>
                    <Typography data-testid="fsr-profile-email" component="span" sx={bodyStyle}>
                        {profile?.emailId}
                    </Typography>
                    {profile?.userId && (
                        <Typography component="span" sx={profileIdStyle}>
                            User ID: <span>{profile.userId}</span>
                        </Typography>
                    )}
                </Typography>
                <MenuItem sx={{ padding: "8px 16px", borderWidth: 0 }}>
                    <Typography data-testid="fsr-profile-logout" component="a" sx={linkStyle} href={logoutLink}>
                        Logout
                    </Typography>
                </MenuItem>
            </Menu>
        </ThemeMui>
    );
}
