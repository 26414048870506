import GridViewIcon from "@mui/icons-material/GridView";
import { Box, ListItemButton, ThemeProvider } from "@mui/material";
import React from "react";

import { CustomListItemText, CustomSecondaryAction, TagCountText } from "./stylesheet";
import { FsrMuiTheme } from "../../themes/FsrMuiTheme";
import { getResourceIdFromSearchResult, SearchQueryResult } from "../../types/Search";
import GlobalSearchResultTag from "../GlobalSearchResultTag/GlobalSearchResultTag";
import HighlightedText from "../HighlightedText/HighlightedText";
import { RenderTagFunc } from "../SearchResultsTag/SearchResultsTag";

export interface GlobalSearchResultItemProps {
    query: string;
    searchData: SearchQueryResult;
    renderTags?: RenderTagFunc;
    onLinkClicked: (resourceId: string | undefined, tagIds: string[] | undefined) => void;
    onTagLinkClicked: (tagId: string) => void;
}

export default function GlobalSearchResultItem(props: GlobalSearchResultItemProps): JSX.Element {
    const [showTags, setShowTags] = React.useState<boolean>(false);
    const tagsCount = props.searchData.content?.staticProperties?.tags?.length || 0;
    const tags = props.searchData.content?.staticProperties?.tags as string[];

    const resourceId = getResourceIdFromSearchResult(props.searchData);

    if (!resourceId) {
        return <></>; // Wrong resource Id format
    }

    function renderTags(): JSX.Element {
        if (!showTags || !tags) {
            return <></>; // No tag for this resource
        }

        // For consumer to override tags
        if (props.renderTags && resourceId?.resourceId) {
            return (
                <Box key={`tag-${resourceId?.resourceId}`}>
                    {props.renderTags(resourceId.resourceId, tags, props.onTagLinkClicked)}
                </Box>
            );
        }

        // Default view of tags, return as-is without modifying
        return (
            <Box key={`tag-${resourceId?.resourceId}`}>
                {tags.map((tag, i) => {
                    return <GlobalSearchResultTag key={i} tagId={tag} onTagLinkClicked={props.onTagLinkClicked} />;
                })}
            </Box>
        );
    }

    function toggleShowTags(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        e.stopPropagation();
        setShowTags((prev) => !prev);
    }

    if (tagsCount === 0) {
        // Return a simple link to open in new tab
        return (
            <ListItemButton
                key={resourceId.resourceId}
                component="a"
                href={`/schema/${resourceId.resourceId}`}
                target="_blank"
            >
                <CustomListItemText
                    primary={<HighlightedText input={resourceId.schemaName} highlighted={props.query} />}
                    secondary={<HighlightedText input={resourceId.resourceName} highlighted={props.query} />}
                />
            </ListItemButton>
        );
    }

    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <Box key={resourceId.resourceId}>
                <ListItemButton
                    key={resourceId.resourceId}
                    onClick={() => props.onLinkClicked(resourceId.resourceId, tags)}
                >
                    <CustomListItemText
                        primary={<HighlightedText input={resourceId.schemaName} highlighted={props.query} />}
                        secondary={<HighlightedText input={resourceId.resourceName} highlighted={props.query} />}
                    />
                    <CustomSecondaryAction onClick={toggleShowTags}>
                        <GridViewIcon sx={{ fontSize: "12px" }} />
                        {tagsCount > 0 && <TagCountText>{tagsCount}</TagCountText>}
                    </CustomSecondaryAction>
                </ListItemButton>
                {renderTags()}
            </Box>
        </ThemeProvider>
    );
}
