import { Cookies } from "react-cookie";

export type SplitterSide = "left" | "right";

export class SplitterMouseHandler {
    private _panel: HTMLElement | undefined;
    private readonly _side: SplitterSide;
    private readonly _minWidth: number;
    private readonly _targetId: string;
    private readonly _splitter: HTMLDivElement;
    private _oldWidth = 0;
    private _newWidth = 0;
    private _downScreenX = 0;

    constructor(splitter: HTMLDivElement, side: SplitterSide, minWidth: number, targetId: string) {
        this.onSplitterMouseMove = this.onSplitterMouseMove.bind(this);
        this.onSplitterMouseUp = this.onSplitterMouseUp.bind(this);
        this.onSplitterMouseDown = this.onSplitterMouseDown.bind(this);

        this._splitter = splitter;
        this._side = side;
        this._targetId = targetId;
        this._minWidth = minWidth | 0; // Only integers
        this._splitter.addEventListener("pointerdown", this.onSplitterMouseDown);
    }

    private onSplitterMouseMove(ev: PointerEvent) {
        if (this._panel) {
            let delta = ev.screenX - this._downScreenX;
            delta = this._side === "right" ? delta : -delta;
            this._newWidth = (this._oldWidth + delta) | 0; // Only integers

            // Do not save negative width
            if (this._newWidth < this._minWidth) {
                this._newWidth = this._minWidth;
            }

            const width = `${this._newWidth}px`;
            this._panel.style.width = width;
            this._panel.style.minWidth = width;
        }
    }

    private onSplitterMouseUp(ev: PointerEvent) {
        this._panel = undefined;

        const splitter = this._splitter;
        splitter.removeEventListener("pointermove", this.onSplitterMouseMove);
        splitter.removeEventListener("pointerup", this.onSplitterMouseUp);
        splitter.releasePointerCapture(ev.pointerId);

        // Save width in Cookie
        const cookies = new Cookies();
        const cookieName = `fsr-panel-width-${this._targetId}`;
        cookies.set(cookieName, this._newWidth, { path: "/" });
    }

    private onSplitterMouseDown(ev: PointerEvent) {
        ev.stopPropagation();
        ev.preventDefault();

        const element = document.getElementById(this._targetId);
        this._panel = element as HTMLElement;

        // Store the 'left' edge of parent for new width computation later
        const rect = this._panel.getBoundingClientRect();
        this._oldWidth = rect.width;
        this._downScreenX = ev.screenX;

        const splitter = this._splitter;
        splitter.setPointerCapture(ev.pointerId);
        splitter.addEventListener("pointermove", this.onSplitterMouseMove);
        splitter.addEventListener("pointerup", this.onSplitterMouseUp);
    }
}
