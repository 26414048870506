import React from "react";
import { AlertDispatchContext, AlertSeverity } from "../providers/AlertProvider";
import { Action } from "../types/ActionSpecs";

export type AlertMessageType = string | Record<string, string>;

export interface AlertFunctions {
    success: (message: AlertMessageType, options?: AlertOptions) => void;
    info: (message: AlertMessageType, options?: AlertOptions) => void;
    warning: (message: AlertMessageType, options?: AlertOptions) => void;
    error: (message: AlertMessageType, options?: AlertOptions) => void;
    dismiss: () => void;
}

export interface AlertOptions {
    autoHideMs?: number; // 0 to disable auto-hide
    actions?: Action[];
    onActionClicked?: (id: string) => void;
}

export default function useGlobalAlert(): AlertFunctions {
    const dispatchAlert = React.useContext(AlertDispatchContext);

    function unwrap(message: AlertMessageType): string {
        if (typeof message === "string") {
            return message;
        }

        if (message) {
            const parts = [message["errorCode"], message["developerMessage"], message["message"]];
            message = parts.filter((part) => !!part).join(" ");
        }

        return message || "Unknown message";
    }

    function dispatchMessage(severity: AlertSeverity, message: AlertMessageType, options?: AlertOptions): void {
        dispatchAlert({
            action: "alert",
            content: {
                message: unwrap(message),
                severity: severity,
                autoHideMs: options?.autoHideMs,
                actions: options?.actions,
                onActionClicked: options?.onActionClicked,
            },
        });
    }

    function success(message: AlertMessageType, options?: AlertOptions): void {
        dispatchMessage("success", message, options);
    }

    function info(message: AlertMessageType, options?: AlertOptions): void {
        dispatchMessage("info", message, options);
    }

    function warning(message: AlertMessageType, options?: AlertOptions): void {
        dispatchMessage("warning", message, options);
    }

    function error(message: AlertMessageType, options?: AlertOptions): void {
        dispatchMessage("error", message, options);
    }

    function dismiss(): void {
        dispatchAlert({ action: "clear" });
    }

    return { success, info, warning, error, dismiss };
}
