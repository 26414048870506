import { ListItemButton, ThemeProvider } from "@mui/material";
import React from "react";
import { CustomGridViewIcon, CustomTypography } from "./stylesheet";
import { FsrMuiTheme } from "../../themes/FsrMuiTheme";
import { StringVoidFunc } from "../../types/Functions";

export interface RenderTagFunc {
    (resourceId: string, tagIds: string[], onClick: StringVoidFunc): JSX.Element;
}

export interface SearchResultsTagProps {
    tagId: string;
    onTagLinkClicked: StringVoidFunc;
}

export default function SearchResultsTag(props: SearchResultsTagProps): JSX.Element {
    function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.stopPropagation();
        props.onTagLinkClicked(props.tagId);
    }

    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <ListItemButton
                sx={{ padding: "0.1rem 1rem" }}
                onClick={handleClick}
                data-testid="fsr-overview-searchresult-item"
            >
                <CustomGridViewIcon />
                <CustomTypography>{props.tagId}</CustomTypography>
            </ListItemButton>
        </ThemeProvider>
    );
}
