import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import { callGetApi } from "./utils/apiCall";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { useAppSelector } from "../redux/hooks";
import { Tag, TagResponse } from "../types/Tag";

export default function useGroupFiltersInCategories(
    groupId?: string,
    categories?: Tag[],
    offset?: number,
    limit?: number
): QueryHookOutput<Tag[]> {
    const token = useAppSelector((state) => state.auth.token);

    [offset, limit] = validateOffsetLimit(offset, limit);

    return useQuery({
        enabled: !!(groupId && token),
        queryKey: [`filters-in-categories-${categories?.map((c) => c.id).join(".")}-${offset}-${limit}`],
        queryFn: async () => {
            categories = categories ?? [];

            const categoryTags = categories.map(async (category) => {
                const queryParams = new URLSearchParams({
                    categoryIds: category.id,
                    offset: `${offset}`,
                    limit: `${limit}`,
                });

                const response = await callGetApi<TagResponse<Tag[]>>(
                    `/tag-collections/${groupId}/tags?${queryParams}`,
                    token
                );
                const groupFilters = response?.results.map((tag) => {
                    return {
                        name: tag.name,
                        title: tag.userData?.title,
                        description: tag.userData?.description,
                        tagId: tag.id,
                    };
                });
                return { name: category.name, title: category.userData?.title, groupFilters };
            });

            const filters = await Promise.all(categoryTags);

            return filters;
        },
    });
}
