import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { callPostApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { LibraryRequestData } from "../types/Library";

export default function useCreateLibrary(organizationId?: string, groupId?: string): UseMutationResult {
    const token = useAppSelector((state) => state.auth.token);

    return useMutation((formData) => {
        const requestBody = formData as LibraryRequestData;
        return callPostApi(`/organizations/${organizationId}/groups/${groupId}/libraries`, token, requestBody);
    });
}
