import lightThemeData from "@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json";
import { Box, styled } from "@mui/material";

export const FooterBox = styled(Box)({
    fontFamily: lightThemeData.primitives.fontFamilies.default,
    backgroundColor: "#f5f5f5",
    padding: "20px 0",
    flex: 1,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    "&>span": {
        fontSize: 12,
        color: "#3c3c3cb3",
        lineHeight: 1,
    },
    "&>p": {
        display: "inline-block",
        fontSize: 10,
        color: "#3c3c3cb3",
        margin: "0 0.5rem",
        padding: 6,
        lineHeight: 1,
    },
    "&>a": {
        display: "inline-block",
        fontSize: 10,
        textDecoration: "none",
        color: "#3c3c3cb3",
        margin: "0 4px",
        padding: 6,
        whiteSpace: "nowrap",
        lineHeight: 1,
        "&:hover": {
            textDecoration: "underline",
        },
    },
    "@media (max-width: 1023px)": {
        flexWrap: "wrap",
    },
    "@media (max-width: 767px)": {
        flexDirection: "column",
        "&>span": {
            display: "none",
        },
    },
}) as typeof Box;
