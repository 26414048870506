import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { callPutApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { LibraryRequestData } from "../types/Library";

export default function useUpdateLibrary(
    organizationId?: string,
    groupId?: string,
    libraryId?: string
): UseMutationResult {
    const token = useAppSelector((state) => state.auth.token);

    return useMutation((formData) => {
        const requestBody = formData as LibraryRequestData;
        return callPutApi(
            `/organizations/${organizationId}/groups/${groupId}/libraries/${libraryId}`,
            token,
            requestBody
        );
    });
}
