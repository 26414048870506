import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext, ApplicationContext } from "../../ApplicationContext";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navigation/NavBar";
import useGlobalAlert from "../../hooks/useGlobalAlert";
import useQuery from "../../hooks/useQuery";
import i18n from "../../i18n";
import AuthRequest from "../../models/AuthRequest";
import { signIn, signOut } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AuthErrorResponse } from "../../types/AuthResponse";
import Layout from "../../ui-components/Layout";
import ProgressRing from "../../ui-components/ProgressRing/ProgressRing";

import {
    generateCodeChallenge,
    generateCodeVerifier,
    loadLocationState,
    storeLocationState,
} from "../../utils/authentication";

enum AuthAction {
    Verify = "verify",
    Callback = "callback",
    Login = "login",
    Logout = "logout",
}

const actionLabels: Record<AuthAction, string> = {
    [AuthAction.Verify]: i18n.t("labels.verifyingSignIn"),
    [AuthAction.Callback]: i18n.t("labels.progressSigningIn"),
    [AuthAction.Login]: i18n.t("labels.redirectOuthSignIn"),
    [AuthAction.Logout]: i18n.t("labels.signingOut"),
};

export default function AuthHandler(): JSX.Element {
    const { action } = useParams();
    const { state } = useLocation();
    const query = useQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const alerts = useGlobalAlert();
    const token = useAppSelector((state) => state.auth.token);

    const appContext = React.useContext<AppContext>(ApplicationContext);
    const basePath = appContext.env.REACT_APP_BASE_PATH;

    async function handleVerification(): Promise<void> {
        // Save previous location
        storeLocationState(state);
        // save in session for callback
        const newVerifier = await generateCodeVerifier();
        sessionStorage.setItem("fss-auth-verifier", newVerifier);
        // Prepare to authorize
        const auth = new AuthRequest();
        const challenger = await generateCodeChallenge(newVerifier);
        const loginLink = await auth.noPromptAuthorizeUrl(challenger);

        // Without the following line, pressing the browser 'back' button at the
        // repo page would incorrectly bring user to '/login/verify' and get stuck
        // there. The following line adds '/home' to browser history so that the
        // 'back' button would return to '/home'. User will still go through the
        // login redirection but at least they're not stuck at '/login/verify' page.
        window.history.replaceState({}, "", `${basePath}/home`);
        window.location.href = loginLink; // redirect to login
    }

    function handleLoginClicked(): void {
        // Remove query string from URL before redirect
        window.history.replaceState({}, "", `/`);
        navigate("/oauth/login", { replace: true });
    }

    async function handleAuthCallback(): Promise<void> {
        const code = query.get("code");
        if (query.get("error") || !code) {
            // If error exists, verification failed, need to login via prompt
            return navigate("/oauth/login", { replace: true });
        }

        const verifier = sessionStorage.getItem("fss-auth-verifier");

        // Else, continue to get token
        if (!token && verifier) {
            try {
                const auth = new AuthRequest();
                const result = await auth.getToken(code, verifier);
                dispatch(signIn(result));
                // Remove from storage
                sessionStorage.removeItem("fss-auth-verifier");

                // Remove query string from URL before redirect
                window.history.replaceState({}, "", `${basePath}/`);
                const to = loadLocationState();
                navigate(to, { replace: true });
            } catch (e) {
                const error = e as AuthErrorResponse;
                // When a new tab is opened, the token in Redux is lost and the following error types will be
                // thrown. Automatically redirect to Oxygen login page instead of showing an error message.
                if (error.error === "invalid_grant" || error.error === "no_result_400") {
                    return navigate("/oauth/login", { replace: true });
                }

                alerts.error(error.error_description, {
                    actions: [
                        {
                            id: "login",
                            text: i18n.t("buttons.signIn"),
                            variant: "outlined",
                        },
                    ],
                    onActionClicked: handleLoginClicked,
                });
            }
        }
    }

    async function handleAuthLogin(): Promise<void> {
        // Save in session for callback
        const newVerifier = await generateCodeVerifier();
        sessionStorage.setItem("fss-auth-verifier", newVerifier);
        // Prepare to authorize
        const auth = new AuthRequest();
        const challenger = await generateCodeChallenge(newVerifier);
        const loginLink = await auth.authorizeUrl(challenger);
        window.location.href = loginLink; // redirect to login
    }

    function handleAuthLogout(): void {
        // Remove from storage
        sessionStorage.removeItem("fss-auth-verifier");
        dispatch(signOut());
        // Remove query string from URL before redirect
        window.history.replaceState({}, "", `${basePath}/`);
        navigate("/");
    }

    React.useEffect(() => {
        if (action === AuthAction.Verify) {
            handleVerification();
        } else if (action === AuthAction.Callback) {
            handleAuthCallback();
        } else if (action === AuthAction.Login) {
            handleAuthLogin();
        } else if (action === AuthAction.Logout) {
            handleAuthLogout();
        }
    }, [action]);

    return (
        <Layout header={<NavBar />} footer={<Footer />}>
            <ProgressRing label={action ? actionLabels[action] : "Loading..."} />
        </Layout>
    );
}
