import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import fetchPaginated from "./utils/fetchPaginated";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { useAppSelector } from "../redux/hooks";
import { Tag, TagResponse } from "../types/Tag";

export default function useCategoriesInGroup(
    groupId?: string,
    offset?: number,
    limit?: number
): QueryHookOutput<Tag[]> {
    const token = useAppSelector((state) => state.auth.token);

    [offset, limit] = validateOffsetLimit(offset, limit);
    const path = `/tag-collections/${groupId}/categories`;

    return useQuery({
        enabled: !!(groupId && token),
        queryKey: [`categories-by-group-${groupId}-${offset}-${limit}`],
        queryFn: async () => {
            return await fetchPaginated<Tag, TagResponse<Tag[]>>(path, token, offset, limit);
        },
    });
}
