import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FooterUI from "../../ui-components/Footer/Footer";

export interface FooterItem {
    title: string;
    url?: string;
    target?: "_blank" | "_parent" | "_self" | "_top";
    // Used as script to prevent link's default onClick, for use in Privacy settings
    isScript?: boolean;
    additionalProps?: Record<string, string>;
}

export interface FooterProps {
    list: FooterItem[];
}

function Footer(props: WithTranslation): JSX.Element {
    const items: FooterItem[] = [
        {
            title: props.t("footer.privacySettings"),
            isScript: true,
            additionalProps: {
                "data-opt-in-preferences": "true",
            },
        },
        {
            title: props.t("footer.doNotSellPersonalInfo"),
            isScript: true,
            additionalProps: {
                "data-wat-linkname": "manage-ccpa-settings-footer-link",
            },
        },
        {
            title: props.t("footer.privacyCookie"),
            url: "https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement",
            target: "_blank",
        },
        {
            title: props.t("footer.copyright"),
        },
    ];

    return <FooterUI list={items} />;
}

export default withTranslation()(Footer);
