export const selectBtnStyle = {
    cursor: "pointer",
    padding: "8px 16px",
    display: "flex",
    color: "#fff",
    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
};

export const selectTextStyle = {
    fontSize: 14,
    fontWeight: "700",
    color: "#fff",
    marginRight: "3px",
};

export const selectMenuStyle = {
    mt: "35px",
};

export const selectMenuPaperStyle = {
    style: {
        borderRadius: 0,
    },
};

export const selectMenuListStyle = {
    style: {
        minWidth: "240px",
    },
};

export const selectMenuTextStyle = {
    fontSize: 14,
    fontWeight: "600",
    marginRight: 1,
    flex: 1,
};

export const selectMenuItemLogoStyle = {
    fontSize: 16,
    display: "flex",
    mr: 1,
};

export const selectMenuItemIconCheckedStyle = {
    fontSize: 16,
    display: "flex",
};
