import { Group } from "../types/Group";

export interface Sortable {
    id: string;
    name: string;
    sequence?: number;
}

export function sortGroupBySequence(a: Group, b: Group): number {
    return sortBySequence(
        { id: a.name, name: a.name, sequence: a.userData?.sequence },
        { id: b.name, name: b.name, sequence: b.userData?.sequence }
    );
}

export function sortBySequence(a: Sortable, b: Sortable): number {
    const aSequence: number | undefined = a.sequence;
    const bSequence: number | undefined = b.sequence;

    if (aSequence !== undefined && bSequence !== undefined) {
        // if a and b are of equal sequence, sort by names
        if (aSequence === bSequence) {
            return a.name > b.name ? 1 : -1;
        }

        // Sort by ascending sequence
        return aSequence > bSequence ? 1 : -1;
    } else if (aSequence !== undefined) {
        return -1; // b has no sequence, put a before b
    } else if (bSequence !== undefined) {
        return 1; // a has no sequence, put b before a
    } else {
        return a.name > b.name ? 1 : -1;
    }
}
