export const profileBtnStyle = {
    p: 0,
    borderRadius: 32,
};

export const profileAvatarStyle = {
    height: 32,
    width: 32,
    bgcolor: "rgb(61, 106, 194)",
    color: "#fff",
    fontSize: 14,
    "&:hover": {
        bgcolor: "rgba(61, 106, 194, 0.8)",
    },
};

export const profileInfoStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "8px 16px",
};

export const titleStyle = {
    fontSize: 16,
    fontWeight: "700",
};

export const bodyStyle = {
    fontSize: 12,
};

export const profileIdStyle = {
    fontSize: 12,
    marginTop: "1rem",
    "& span": {
        color: "#fff",
    },
};

export const linkStyle = {
    textAlign: "left",
    fontSize: 14,
    textDecoration: "none",
    flex: 1,
};

export const menuListStyle = {
    style: {
        minWidth: "240px",
    },
};
