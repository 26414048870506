import { Link } from "@mui/material";
import React from "react";
import { FooterBox } from "./stylesheet";
import { FooterItem, FooterProps } from "../../components/Footer/Footer";

export default function Footer(props: FooterProps): JSX.Element {
    function renderSeparator(): JSX.Element {
        return <span key="item-separator">|</span>;
    }

    function renderItem(item: FooterItem, key: number) {
        if (item.url) {
            return (
                <Link key={`footer-item-${key}`} href={item.url} target={item.target ?? "_blank"}>
                    {item.title}
                </Link>
            );
        }

        if (item.isScript) {
            // Script is still a link but we prevent default onClick to allow script to work without redirect to #.
            //
            // This resolved an error message by React:
            // Warning: A future version of React will block javascript: URLs as a security precaution.
            // Ref: https://reactjs.org/blog/2019/08/08/react-v16.9.0.html#deprecating-javascript-urls
            return (
                <Link key={`footer-item-${key}`} href="#" {...item.additionalProps} onClick={(e) => e.preventDefault()}>
                    {item.title}
                </Link>
            );
        }

        return <p key={`footer-item-${key}`}>{item.title}</p>;
    }

    return (
        <FooterBox key="fsr-footer-content-key" id="fsr-footer-content">
            {
                props.list
                    .map<React.ReactNode>((l, key) => renderItem(l, key)) // Converts to respective component
                    .reduce((prev, curr) => [prev, renderSeparator(), curr]) // Insert separator in between items
            }
        </FooterBox>
    );
}
