import { Container, Toolbar } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentWrapperBox, LogoWrapperBox, StyledAppBar, TitleTypography } from "./stylesheet";
import { AppContext, ApplicationContext } from "../../ApplicationContext";
import logo from "../../assets/autodesk-logo-inverse.png";

export interface NavbarProps {
    onClickLogo?: () => void;
    children?: React.ReactNode;
}

export default function Navbar(props: NavbarProps): JSX.Element {
    const { t } = useTranslation();
    const appContext = React.useContext<AppContext>(ApplicationContext);

    let env: string | undefined;
    if (appContext.env.REACT_APP_ENV !== "prod") {
        env = appContext.env.REACT_APP_ENV.toUpperCase();
    }

    return (
        <StyledAppBar position="static" data-testid="fsr-navbar-ui">
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <ContentWrapperBox>
                        <LogoWrapperBox onClick={props.onClickLogo}>
                            <img className="fsr-logo-image" src={logo} />
                            <TitleTypography component="span">
                                {t("app.title")}
                                {env && <small> ({env})</small>}
                            </TitleTypography>
                        </LogoWrapperBox>
                    </ContentWrapperBox>
                    {props.children}
                </Toolbar>
            </Container>
        </StyledAppBar>
    );
}
