import ProfileIcon from "@digital-hig/icon/lib/build/icons/actions/profile";
import { Search } from "@mui/icons-material";
import { Box, Button, Icon, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalSearchWidget from "./GlobalSearchWidget/GlobalSearchWidget";
import ProfileFlyout from "./ProfileFlyout/ProfileFlyout";
import SecondaryNavBar from "./SecondaryNavBar";
import { buttonStyle, signInTextStyle } from "./stylesheet";
import { SearchContext, useThrottledQuery } from "../../providers/SearchManagerProvider";
import { useAppSelector } from "../../redux/hooks";
import NavbarUI from "../../ui-components/Navbar/Navbar";

export default function NavBar(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const isLogin = useAppSelector((state) => state.auth.isLogin);
    const { t } = useTranslation();

    function renderProfile(): JSX.Element {
        if (isLogin) {
            return <ProfileFlyout />;
        }

        return (
            <Button
                data-testid="fsr-navbar-signin-btn"
                variant="text"
                onClick={() => navigate("/oauth/verify")}
                sx={buttonStyle}
            >
                <Icon id="dhig--icons--actions--profile" sx={{ fontSize: 24, display: "flex" }}>
                    <ProfileIcon />
                </Icon>
                <Typography component="span" sx={signInTextStyle}>
                    {t("buttons.signIn")}
                </Typography>
            </Button>
        );
    }

    const renderSearchBar = () => {
        const searchManager = React.useContext(SearchContext);
        const [query, setQuery] = useThrottledQuery(searchManager.query, (s) => searchManager.setQuery(s), 400);

        if (!isLogin) {
            return <></>;
        } else if (location.pathname === "/search") {
            return (
                <TextField
                    variant="standard"
                    value={query}
                    onChange={(s) => setQuery(s.target.value)}
                    fullWidth
                    sx={{
                        backgroundColor: "#FFFFFF1F",
                        borderRadius: 1,
                        alignContent: "center",
                        input: {
                            color: "#FFFFFF",
                            padding: 1,
                            fontSize: 16,
                        },
                        "& .MuiInput-root": {
                            paddingLeft: 1,
                        },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ alignContent: "center" }}>
                                <Search sx={{ fontSize: 20, color: "#FFFFFF" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            );
        } else {
            return <GlobalSearchWidget />;
        }
    };

    return (
        <>
            <NavbarUI onClickLogo={() => navigate(isLogin ? "/home" : "/")}>
                <Box sx={{ flex: 1, padding: "0px 1rem", textAlign: "center" }}>{renderSearchBar()}</Box>
                <Box sx={{ flex: isLogin ? 1 : 0, textAlign: "right" }}>{renderProfile()}</Box>
            </NavbarUI>
            {isLogin && <SecondaryNavBar />}
        </>
    );
}
