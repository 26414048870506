import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const PopoverStyle = {
    "& .MuiPaper-root.MuiPopover-paper": { top: "0.5rem !important" },
};

export const CustomButton = styled(MuiButton)({
    boxShadow: "none",
    textTransform: "none",
    padding: "6px 12px",
    border: 0,
    fontFamily: "ArtifaktElement,sans-serif",
    backgroundColor: "rgba(100,100,100,0.2)",
    color: "rgba(255,255,255,0.6)",
    justifyContent: "space-between",
    maxWidth: "280px",
    height: "40px",
    "& .MuiButton-startIcon": {
        "& .MuiSvgIcon-root": {
            color: "rgba(255,255,255,0.6)",
        },
    },
    "& .MuiButton-endIcon": {
        "& .MuiSvgIcon-root": {
            color: "rgba(255,255,255,0.6)",
        },
    },
    "&:hover": {
        boxShadow: "none",
        backgroundColor: "rgba(100,100,100,0.3)",
        color: "rgba(255,255,255,1)",
        border: 0,
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "rgba(100,100,100,0.3)",
        color: "rgba(255,255,255,1)",
        border: 0,
    },
    "&:focus": {
        boxShadow: "none",
        border: 0,
    },
    "& > span.fsr-btn-text": {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
});
