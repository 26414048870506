import { CloseOutlined, Search } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, IconButton, InputAdornment, List, Skeleton, ThemeProvider } from "@mui/material";
import React from "react";
import {
    CustomItemFooter,
    CustomItemFooterText,
    CustomItemHeader,
    CustomListItemText,
    CustomSubtleText,
    CustomTextField,
    wrapperBoxStyle,
} from "./stylesheet";
import { useOrganizations } from "../../hooks";
import { SearchContext } from "../../providers/SearchManagerProvider";
import { FsrMuiTheme } from "../../themes/FsrMuiTheme";
import GlobalSearchResultItem from "../GlobalSearchResultItem/GlobalSearchResultItem";
import OrganizationSelector from "../OrganizationSelector/OrganizationSelector";
import { RenderTagFunc } from "../SearchResultsTag/SearchResultsTag";

export interface GlobalSearchFlyoutProps {
    hideOrgSelector?: boolean;
    renderTags?: RenderTagFunc;
    onCloseClicked: () => void;
    onLinkClicked: (resourceId: string | undefined, tagIds: string[] | undefined) => void;
    onTagLinkClicked: (tagId: string) => void;
    onSearchAllButtonClicked: (query: string) => void;
}

export default function GlobalSearchFlyout(props: GlobalSearchFlyoutProps): JSX.Element {
    const searchManager = React.useContext(SearchContext);

    const organisations = useOrganizations();
    const selectedOrgName = searchManager.organizationsSelected.at(0);

    function handleOrganizationChanged(organization: string): void {
        searchManager.setOrganizationsSelected([organization]);
    }

    function renderSearchResult(): JSX.Element {
        if (searchManager.query === "") {
            return <></>;
        }

        if (!searchManager.loading && searchManager.results.length === 0) {
            return <CustomSubtleText>No results</CustomSubtleText>;
        }

        return (
            <List component="div" aria-label="search results" sx={{ p: 0 }}>
                <CustomItemHeader onClick={() => props.onSearchAllButtonClicked(searchManager.query)}>
                    <Search sx={{ fontSize: 20, mr: 1 }} />
                    <CustomListItemText
                        primary={searchManager.query || "Search query"}
                        secondary="Search schemas in Autodesk"
                    />
                    <OpenInNewIcon sx={{ fontSize: 20, ml: 1, color: "#666666" }} />
                </CustomItemHeader>
                {searchManager.results.map((option) => (
                    <GlobalSearchResultItem
                        key={option.searchResultId?.id}
                        query={searchManager.query}
                        searchData={option}
                        onLinkClicked={props.onLinkClicked}
                        onTagLinkClicked={props.onTagLinkClicked}
                        renderTags={props.renderTags}
                    />
                ))}
                <CustomItemFooter onClick={() => props.onSearchAllButtonClicked(searchManager.query)}>
                    <CustomItemFooterText primary={`Show all ${searchManager.total || "0"} results`} />
                </CustomItemFooter>
            </List>
        );
    }

    function renderLoadingSkeleton(): JSX.Element {
        return (
            <Box sx={{ padding: "1.8rem" }}>
                <Skeleton animation="wave" width="50%" sx={{ mb: 1 }} />
                <Skeleton animation="wave" width="30%" sx={{ mb: 1 }} />
                <Skeleton animation="wave" width="60%" sx={{ mb: 1 }} />
                <Skeleton animation="wave" width="80%" />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <Box sx={wrapperBoxStyle}>
                <CustomTextField
                    id="search-textbox"
                    placeholder="Search schemas"
                    fullWidth
                    size="small"
                    value={searchManager.query}
                    onChange={(event) => searchManager.setQuery(event.target.value)}
                    autoFocus
                    variant="standard"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ m: 0 }}>
                                <Search sx={{ fontSize: 20 }} />
                                {!props.hideOrgSelector && (
                                    <OrganizationSelector
                                        organizations={organisations.data || []}
                                        organizationName={selectedOrgName}
                                        onOrganizationChanged={handleOrganizationChanged}
                                    />
                                )}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={props.onCloseClicked}
                                    edge="end"
                                >
                                    <CloseOutlined sx={{ fontSize: 20 }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {searchManager.loading ? renderLoadingSkeleton() : renderSearchResult()}
            </Box>
        </ThemeProvider>
    );
}
