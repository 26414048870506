import Button from "@digital-hig/button";
import Icon from "@digital-hig/icon";
import IconError from "@digital-hig/icon/lib/build/display-icons/error";
import ThemeMui from "@digital-hig/theme-mui";
import React from "react";
import { withTranslation } from "react-i18next";
import { ActionButtonBox, BodyTypography, ContentWrapperBox, EmptyStateIconBox, TitleTypography } from "./stylesheet";
import { ActionSpecs } from "../../types/ActionSpecs";

export interface EmptyStateProps {
    title?: string;
    body: string;
    primaryAction?: string | ActionSpecs | React.ReactNode;
    secondaryAction?: string | ActionSpecs | React.ReactNode;
    icon?: React.ReactNode;
    children?: React.ReactNode;
    onPrimaryActionClicked?: () => void;
    onSecondaryActionClicked?: () => void;
}

type ButtonId = "primary" | "secondary";

function EmptyState(props: EmptyStateProps): JSX.Element {
    function handleButtonClicked(id: ButtonId): void {
        if (id === "primary" && props.onPrimaryActionClicked) {
            props.onPrimaryActionClicked();
        } else if (id === "secondary" && props.onSecondaryActionClicked) {
            props.onSecondaryActionClicked();
        }
    }

    function renderDefaultIcon(): JSX.Element {
        return (
            <Icon id="dhig--icons--display--error" style={{ fontSize: "64px" }}>
                <IconError />
            </Icon>
        );
    }

    function renderButton(id: ButtonId, button: ActionSpecs): JSX.Element {
        return (
            <Button
                startIcon={button.startIcon}
                endIcon={button.endIcon}
                variant={button.variant}
                size="dense"
                onClick={() => handleButtonClicked(id)}
                data-testid={`empty-state-button-${button.id}`}
                disabled={button.disabled}
                color={button.color ?? "primary"}
                sx={{ mr: 3 }}
            >
                {button.text}
            </Button>
        );
    }

    function renderActionButton(id: ButtonId): JSX.Element | null {
        const action = id == "primary" ? props.primaryAction : props.secondaryAction;

        if (React.isValidElement(action)) {
            return action;
        } else if (action) {
            let button: ActionSpecs = { id, text: "", variant: "contained" };
            if (typeof action === "string") {
                button.text = action;
            } else {
                button = action as ActionSpecs;
            }
            return renderButton(id, button);
        } else {
            return null;
        }
    }

    function renderActions(): JSX.Element | null {
        if (!props.primaryAction && !props.secondaryAction) {
            return null;
        }

        return (
            <ActionButtonBox>
                {renderActionButton("primary")}
                {renderActionButton("secondary")}
            </ActionButtonBox>
        );
    }

    return (
        <ContentWrapperBox>
            <ThemeMui>
                <EmptyStateIconBox>{props.icon ?? renderDefaultIcon()}</EmptyStateIconBox>
                {props.title && <TitleTypography data-testid="empty-state-title">{props.title}</TitleTypography>}
                <BodyTypography data-testid="empty-state-body">{props.body}</BodyTypography>
                {renderActions()}
            </ThemeMui>
            {props.children}
        </ContentWrapperBox>
    );
}

export default withTranslation()(EmptyState);
