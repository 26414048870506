import { useQuery } from "@tanstack/react-query";
import { ApiResponse, QueryHookOutput } from "./interfaces/DataHookOutput";
import fetchPaginated from "./utils/fetchPaginated";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { useAppSelector } from "../redux/hooks";
import { SchemaType } from "../types/Schema";
import { SchemaVersionsData } from "../types/SchemaVersion";

export default function useSchemasInLibrary(
    organizationId?: string,
    groupId?: string,
    libraryId?: string,
    schemaTypes?: SchemaType[],
    draftOnly?: boolean,
    offset?: number,
    limit?: number
): QueryHookOutput<SchemaVersionsData[]> {
    const token = useAppSelector((state) => state.auth.token);
    const draft = draftOnly ? "true" : "false";

    [offset, limit] = validateOffsetLimit(offset, limit);

    return useQuery({
        retry: 0,
        enabled: !!(organizationId && groupId && libraryId && token),
        queryKey: [`schemas-by-library-${libraryId}-${schemaTypes?.join(".")}-${draftOnly}-${offset}-${limit}`],
        queryFn: async () => {
            const path = `/organizations/${organizationId}/groups/${groupId}/libraries/${libraryId}/schemas`;
            schemaTypes = schemaTypes ?? [SchemaType.propertySets];
            const responses = await Promise.all(
                schemaTypes.map((schemaType) => {
                    const queryParams = new URLSearchParams({
                        schemaType,
                        draft,
                    });
                    return fetchPaginated<SchemaVersionsData, ApiResponse<SchemaVersionsData[]>>(
                        path,
                        token,
                        offset,
                        limit,
                        queryParams
                    );
                })
            );

            return responses.flat().map((schema) => addSchemaId(schema));
        },
    });
}

function addSchemaId(schema: SchemaVersionsData) {
    return {
        ...schema,
        id: `${schema.schemaType}:${schema.qualifier}:${schema.name}-${schema.semanticVersion}`,
        createdBy: schema.createdBy ?? "",
        createTime: schema.createTime ?? "",
    };
}
