import BaseRequest from "./BaseRequest";
import { Environment } from "../ApplicationContext";
import { SearchQueryResult, SortType } from "../types/Search";
import generateSessionId from "../utils/generateSessionId";

export interface SearchServiceResponse {
    queryId: string;
    time: number;
    messages: string[];
    queryResultCount: number;
    queryResults: SearchQueryResult[];
    queryCategories: unknown[];
    availableSortTypes: SortType[];
    approximateNumberOfPages: number;
    federatedResults: unknown[];
}

export interface SearchInfo {
    clientId: string;
    context?: string;
    domain?: string;
    pid?: string;
    searchData: SearchData;
}

export interface SearchData {
    query: string;
    offset?: number;
    pagesize?: number;
    withCategory?: string;
    filters?: string;
    sort?: SortType;
}

export default class SearchServiceRequest extends BaseRequest<SearchServiceResponse> {
    private readonly _clientId: string;
    private readonly _data: SearchData;
    private readonly _domain: string;
    private readonly _context: string;
    private readonly _pid: string; // Profile ID for Search

    constructor(searchInfo: SearchInfo) {
        super();

        if (!searchInfo || typeof searchInfo !== "object") {
            throw "Invalid mandatory 'searchInfo' parameter";
        }

        if (!searchInfo.clientId || typeof searchInfo.clientId !== "string") {
            throw "Invalid mandatory 'searchInfo.clientId' parameter";
        }

        if (!searchInfo.searchData || typeof searchInfo.searchData !== "object") {
            throw "Invalid mandatory 'searchInfo.searchData' parameter";
        }

        this._clientId = searchInfo.clientId;
        this._context = searchInfo.context || "fssv3";
        this._domain = searchInfo.domain || "autodesk";
        this._pid = searchInfo.pid || "adsk.fssv3.autodesk";
        this._data = searchInfo.searchData;
        this.cacheResponse = false; // No cache for POST
    }

    protected getOptionsCore(): Record<string, string> {
        return {
            method: "POST",
            body: JSON.stringify({
                ...this._data,
                withCategory: "true", // Include tags in search results
            }),
        };
    }

    protected getBasePathCore(env: Environment): string {
        return env.REACT_APP_SEARCH_BASEURL;
    }

    protected getPathCore(): string {
        return `contexts/${this._context}/domains/${this._domain}/_search?pid=${this._pid}`;
    }

    protected getHeadersCore(): Record<string, string> {
        const searchSessionId = generateSessionId();
        return {
            "x-ads-session-id": searchSessionId,
            "x-ads-client-id": this._clientId,
            "x-ads-client-feature-id": "mainSearchBar",
            "x-ads-anon-session": "false",
        };
    }

    protected async extractResultCore(response: Response): Promise<SearchServiceResponse> {
        return await response.json();
    }
}
