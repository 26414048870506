import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import {
    FetchFailure,
    FetchSuccess,
    GroupedLibrariesOption,
    SearchContextAction,
    SearchManagerState,
    SearchManagerType,
    SearchSchemaType,
} from "./SearchManagerTypes";
import { AppContext, ApplicationContext } from "../ApplicationContext";
import { FilterBuilder, FilterType } from "../models/SearchRequest/FilterBuilder";
import SearchServiceRequest, { SearchData, SearchInfo } from "../models/SearchServiceRequest";
import { useAppSelector } from "../redux/hooks";
import { SortType } from "../types/Search";

// SearchManager purely holds the state of the search and the fetching of results.
// SearchManager has no awareness of how selectors provide options to the user
// or how the values are selected

const defaultOrganizations = ["autodesk"];

const defaultSearchState: SearchManagerState = {
    results: [],
    total: 0,
    totalPages: 0,
    loading: false,
    query: "",
    currentPage: 0,
    sortBy: "relevance",
    organizationsSelected: defaultOrganizations,
    groupsSelected: [],
    librariesSelected: [],
    schemaTypeSelected: null,
    inheritsSelected: null,
    componentSelected: null,
    startDate: null,
    endDate: null,
};

const emptyMethod = () => {
    throw new Error("SearchManagerContext not initialized");
};

export const SearchContext = React.createContext<SearchManagerType>({
    pageSize: 0,
    maxResults: 0,
    maxPages: 0,
    ...defaultSearchState,
    setQuery: emptyMethod,
    setCurrentPage: emptyMethod,
    setSortBy: emptyMethod,

    setOrganizationsSelected: emptyMethod,
    setGroupsSelected: emptyMethod,
    setLibrariesSelected: emptyMethod,

    setSchemaTypeSelected: emptyMethod,
    setInheritsSelected: emptyMethod,
    setComponentSelected: emptyMethod,
    setStartDate: emptyMethod,
    setEndDate: emptyMethod,
    searchNow: emptyMethod,
    hardReset: emptyMethod,
});

/**
 * this hook is similar to `useState<string>` with a side effect of synchronising the query
 */
export function useThrottledQuery(
    query: string,
    setQueryCallback: (query: string) => void,
    ms?: number
): [string, (query: string) => void] {
    const [queryState, setQueryState] = useState(query);
    useEffect(() => {
        if (ms === undefined) {
            setQueryCallback(queryState);
            return;
        }
        const timer = setTimeout(() => {
            if (queryState !== query) {
                setQueryCallback(queryState);
            }
        }, ms);

        return () => clearTimeout(timer);
    }, [queryState]);

    useEffect(() => {
        if (queryState !== query) setQueryState(query);
    }, [query]);
    return [queryState, setQueryState];
}

function searchManagerReducer(state: SearchManagerState, action: SearchContextAction): SearchManagerState {
    switch (action.type) {
        case "NoQuery":
            return { ...state, loading: false, results: [], total: 0, totalPages: 0 };
        case "BeginHardSearch":
            return { ...state, loading: true, currentPage: 1 };
        case "BeginSoftSearch":
            return { ...state, loading: true };
        case "FetchSuccess":
            return { ...state, ...action, loading: false };
        case "FetchFailure":
            return { ...state, loading: false };
        case "SetQuery":
            return { ...state, query: action.query };
        case "SetCurrentPage":
            return { ...state, currentPage: action.page };
        case "SetSortBy":
            return { ...state, sortBy: action.sortBy };
        case "SetOrganizationsSelected":
            return {
                ...state,
                organizationsSelected: action.organizations,
            };
        case "SetGroupsSelected":
            return { ...state, groupsSelected: action.groups };
        case "SetLibrariesSelected":
            return {
                ...state,
                librariesSelected: action.libraries,
            };
        case "setSchemaTypeSelected":
            return { ...state, schemaTypeSelected: action.schemaType };
        case "SetInheritsSelected":
            return { ...state, inheritsSelected: action.inherits };
        case "SetComponentSelected":
            return { ...state, componentSelected: action.component };
        case "setStartDate":
            return { ...state, startDate: action.startDate };
        case "setEndDate":
            return { ...state, endDate: action.endDate };
        case "HardReset":
            return defaultSearchState;
        default:
            throw new Error("Invalid Action");
    }
}

export async function _fetchResults(
    pageSize: number,
    currentPage: number,
    query: string,
    filter: string,
    sortBy: SortType,
    clientId: string,
    token: string
): Promise<FetchSuccess | FetchFailure> {
    const offset = (currentPage - 1) * pageSize + 1;
    const searchData: SearchData = {
        offset: offset,
        pagesize: pageSize,
        query: query,
        filters: filter,
        sort: sortBy,
    };

    try {
        const searchInfo: SearchInfo = { clientId, searchData: searchData };
        const request = new SearchServiceRequest(searchInfo);
        const result = await request.invoke(token);

        if (result) {
            // Search profile doesn't support v3 Standard Organization API.
            // Hide the tag collections for now.
            result.queryResults.forEach((r) => {
                if (r.content?.staticProperties?.tags) r.content.staticProperties.tags = [];
            });
        }
        return {
            type: "FetchSuccess",
            results: result.queryResults,
            total: result.queryResultCount || 0,
            totalPages: result.approximateNumberOfPages || 0,
        };
    } catch (_err) {
        console.error(_err);
        return { type: "FetchFailure" };
    }
}

export function setOne<T = string>(
    items: T[],
    item: T,
    selected: "toggle" | boolean,
    allOrderedItems: T[] | "dontPreserve" = "dontPreserve",
    equal: (a: T, b: T) => boolean = (a: T, b: T) => a === b
): T[] {
    if (selected === "toggle") {
        if (items.some((i) => equal(i, item))) {
            selected = false;
        } else {
            selected = true;
        }
    }
    if (selected) {
        if (allOrderedItems === "dontPreserve") {
            items.some((i) => equal(i, item)) || items.push(item);
            return items;
        }
        // for all orderedItem, if the item is selected or the item is the one that was just checked, keep it
        return allOrderedItems.filter((i) => equal(i, item) || items.some((j) => equal(i, j)));
    } else {
        // remove the item from the list
        return items.filter((i) => !equal(i, item));
    }
}

interface SearchManagerProps {
    children: JSX.Element[] | JSX.Element;
}

export default function SearchManagerProvider(props: SearchManagerProps): JSX.Element {
    const [state, dispatch] = React.useReducer(searchManagerReducer, defaultSearchState);
    const appContext = React.useContext<AppContext>(ApplicationContext);
    const clientId = appContext.env.REACT_APP_CLIENT_ID;
    const token = useAppSelector((authState) => authState.auth.token);

    const requestTimestamp = React.useRef<number>(0);

    const pagesize = 50;
    const maxResults = 10000;

    const fetchResultsNow = React.useCallback(async () => {
        const timestamp = Date.now();
        requestTimestamp.current = timestamp;

        if (state.query === "") {
            dispatch({ type: "NoQuery" });
            return;
        }

        const filterObj: FilterType = {};

        // add date filters
        filterObj.before = state.endDate || undefined;
        filterObj.after = state.startDate || undefined;

        // add schema type filters
        switch (state.schemaTypeSelected) {
            case null:
                filterObj.schemaType = undefined;
                break;
            case "forgeDataSchema":
                filterObj.schemaType = ["propertySets" as SearchSchemaType];
                // should update to filters": "(specification>forge-data-schema-0.0.0 AND specification<forge-data-schema-999.0.0)
                filterObj.specification = [
                    "forge-data-schema-1.0.0",
                    "forge-data-schema-1.1.0",
                    "forge-data-schema-1.0.1",
                    "forge-data-schema-2.0.0",
                ];
                break;
            case "propertySets":
            case "gql":
            case "json":
                filterObj.schemaType = [state.schemaTypeSelected as SearchSchemaType];
                break;
            default:
                throw new Error("Invalid schema type");
        }

        filterObj.organization = state.organizationsSelected;
        filterObj.inheritanceIds = state.inheritsSelected ? [state.inheritsSelected] : undefined;
        filterObj.componentIds = state.componentSelected ? [state.componentSelected] : undefined;

        function createFiltersWithGroupedLibrary(groupedLibrary: GroupedLibrariesOption): FilterBuilder {
            return new FilterBuilder({
                group: groupedLibrary.group ? [groupedLibrary.group] : undefined,
                library: groupedLibrary.libraries,
                ...filterObj,
            });
        }

        function createFilterWithoutGroupedLibrary(): FilterBuilder {
            return new FilterBuilder({
                group: state.groupsSelected,
                ...filterObj,
            });
        }

        let filter: string;
        if (state.librariesSelected.length === 0) {
            filter = createFilterWithoutGroupedLibrary().build();
        } else {
            const groupedLibraryFilters = state.librariesSelected.map(createFiltersWithGroupedLibrary);
            filter = FilterBuilder.merge(groupedLibraryFilters);
        }

        const res = await _fetchResults(
            pagesize,
            state.currentPage,
            state.query,
            filter,
            state.sortBy,
            clientId,
            token
        );

        // Only dispatch most recern result to prevent race conditions
        if (timestamp === requestTimestamp.current) {
            dispatch(res);
        }
    }, [
        state.query,
        state.currentPage,
        state.sortBy,
        state.organizationsSelected,
        state.groupsSelected,
        state.librariesSelected,
        state.schemaTypeSelected,
        state.inheritsSelected,
        state.componentSelected,
        state.startDate,
        state.endDate,
    ]);

    useEffect(() => {
        dispatch({ type: "BeginHardSearch" });
        fetchResultsNow();
    }, [
        state.query,
        state.sortBy,
        state.organizationsSelected,
        state.groupsSelected,
        state.librariesSelected,
        state.schemaTypeSelected,
        state.inheritsSelected,
        state.componentSelected,
        state.startDate,
        state.endDate,
    ]);

    useEffect(() => {
        dispatch({ type: "BeginSoftSearch" });
        fetchResultsNow();
    }, [state.currentPage]);

    const value: SearchManagerType = {
        pageSize: pagesize,
        maxResults: maxResults,
        get maxPages(): number {
            return maxResults / pagesize;
        },

        ...state,

        setQuery: (query: string) => dispatch({ type: "SetQuery", query: query }),
        setCurrentPage: (page: number) => dispatch({ type: "SetCurrentPage", page: page }),
        setSortBy: (sortBy: SortType) => dispatch({ type: "SetSortBy", sortBy: sortBy }),

        setOrganizationsSelected: (organizations: string[]) => {
            organizations = organizations.length === 0 ? defaultOrganizations : organizations;
            dispatch({
                type: "SetOrganizationsSelected",
                organizations: organizations,
            });
        },
        setGroupsSelected: (groups: string[]) =>
            dispatch({
                type: "SetGroupsSelected",
                groups: groups,
            }),
        setLibrariesSelected: (libraries: GroupedLibrariesOption[]) =>
            dispatch({
                type: "SetLibrariesSelected",
                libraries: libraries,
            }),
        setSchemaTypeSelected: (schematype: SearchSchemaType | null) =>
            dispatch({ type: "setSchemaTypeSelected", schemaType: schematype }),

        setInheritsSelected: (inherits: string | null) => dispatch({ type: "SetInheritsSelected", inherits: inherits }),
        setComponentSelected: (component: string | null) =>
            dispatch({ type: "SetComponentSelected", component: component }),
        setStartDate: (date: Dayjs | null) => dispatch({ type: "setStartDate", startDate: date }),
        setEndDate: (date: Dayjs | null) => dispatch({ type: "setEndDate", endDate: date }),
        searchNow(): void {
            dispatch({ type: "BeginHardSearch" });
            fetchResultsNow();
        },
        hardReset: () => dispatch({ type: "HardReset" }),
    };

    return <SearchContext.Provider value={value}>{props.children}</SearchContext.Provider>;
}
