import lightThemeData from "@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json";
import { Box, styled } from "@mui/material";
import { customScrollBar } from "../../themes/mixins";

const primitives = lightThemeData.primitives;

export const WrapperBox = styled(Box)({
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
}) as typeof Box;

export const ErrorMessageBox = styled(Box)({
    display: "block",
    fontSize: "14px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: primitives.colors.accent.clay,
    margin: "1em 0 0",
    padding: "1em",
    overflow: "auto",
    ...customScrollBar,
}) as typeof Box;
