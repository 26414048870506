import { appEnvironment } from "../ApplicationContext";
import { AuthErrorResponse, TokenData } from "../types/AuthResponse";
import { UserProfile } from "../types/UserProfile";

export default class AuthRequest {
    private readonly _baseUrl: string;
    private readonly _clientId: string;
    private readonly _callback: string;
    private readonly _scopes: string;
    private readonly _profileUrl: string;

    constructor() {
        this._baseUrl = appEnvironment.REACT_APP_AUTH_BASEURL;
        this._profileUrl = appEnvironment.REACT_APP_PROFILE_BASEURL;
        this._clientId = appEnvironment.REACT_APP_CLIENT_ID;
        this._callback = appEnvironment.REACT_APP_AUTH_CALLBACK;
        this._scopes = "data:read data:write data:create data:search";
    }

    async authorizeUrl(code_challenge: string): Promise<string> {
        const queryParams = new URLSearchParams({
            response_type: "code",
            client_id: this._clientId,
            redirect_uri: `${this._callback}/oauth/callback`,
            scope: this._scopes,
            prompt: "login",
            code_challenge: code_challenge,
            code_challenge_method: "S256",
            state: "login",
        });

        return `${this._baseUrl}/authorize?${queryParams}`;
    }

    async noPromptAuthorizeUrl(code_challenge: string): Promise<string> {
        const queryParams = new URLSearchParams({
            response_type: "code",
            response_mode: "query",
            client_id: this._clientId,
            redirect_uri: `${this._callback}/oauth/callback`,
            scope: this._scopes,
            code_challenge: code_challenge,
            code_challenge_method: "S256",
            prompt: "none",
            state: "verify",
        });

        return `${this._baseUrl}/authorize?${queryParams}`;
    }

    async logoutUrl(): Promise<string> {
        const queryParams = new URLSearchParams({
            post_logout_redirect_uri: `${this._callback}/oauth/logout`,
        });

        return `${this._baseUrl}/logout?${queryParams}`;
    }

    async getToken(code: string, verifier: string): Promise<TokenData> {
        const url = this._baseUrl + "/token";

        const queryParams = new URLSearchParams({
            client_id: this._clientId,
            grant_type: "authorization_code",
            code: code,
            redirect_uri: `${this._callback}/oauth/callback`,
            code_verifier: verifier,
        });

        return this._fetchToken(url, queryParams);
    }

    async refreshToken(refresh_token: string): Promise<TokenData> {
        const url = `${this._baseUrl}/token`;

        const queryParams = new URLSearchParams({
            client_id: this._clientId,
            grant_type: "refresh_token",
            refresh_token: refresh_token,
            scope: this._scopes,
        });

        return this._fetchToken(url, queryParams);
    }

    /**
     * Get profile information of signed-in user
     * @param token Access Token from 3-legged authentication
     */
    async getProfile(token: string): Promise<UserProfile> {
        const response = await fetch(this._profileUrl, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        });

        const result = await response.json();
        return result as UserProfile;
    }

    private async _fetchToken(url: string, queryParams: URLSearchParams): Promise<TokenData> {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                Accept: "application/json",
            },
            body: queryParams.toString(),
        });

        if (!response.ok) {
            throw {
                error: `no_result_${response.status}`,
                error_description: `No result from OAuth due to ${response.statusText}.`,
            } as AuthErrorResponse;
        }

        try {
            const result = (await response.json()) as TokenData;
            result.token_time = Date.now();
            return result;
        } catch (error) {
            throw {
                error: "no_result",
                error_description: "No result from OAuth.",
            } as AuthErrorResponse;
        }
    }
}
