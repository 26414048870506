import { useQuery } from "@tanstack/react-query";
import { ApiResponse, QueryHookOutput } from "./interfaces/DataHookOutput";
import fetchPaginated from "./utils/fetchPaginated";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { useAppSelector } from "../redux/hooks";
import { Library } from "../types/Library";

export default function useLibrariesInGroup(
    organizationId?: string,
    groupId?: string,
    offset?: number,
    limit?: number
): QueryHookOutput<Library[]> {
    const token = useAppSelector((state) => state.auth.token);

    [offset, limit] = validateOffsetLimit(offset, limit);

    const path = `/organizations/${organizationId}/groups/${groupId}/libraries`;

    return useQuery({
        retry: 0,
        enabled: !!(organizationId && groupId && token),
        queryKey: [`libraries-by-group-${groupId}`],
        queryFn: () => fetchPaginated<Library, ApiResponse<Library[]>>(path, token, offset, limit),
    });
}
