import { CloseOutlined, Search } from "@mui/icons-material";
import { Popover, ThemeProvider } from "@mui/material";
import React from "react";
import { CustomButton, PopoverStyle } from "./stylesheet";
import { SearchContext } from "../../providers/SearchManagerProvider";
import { FsrMuiTheme } from "../../themes/FsrMuiTheme";
import GlobalSearchFlyout from "../GlobalSearchFlyout/GlobalSearchFlyout";
import { RenderTagFunc } from "../SearchResultsTag/SearchResultsTag";

export interface GlobalSearchBarProps {
    hideOrgSelector?: boolean;
    renderTags?: RenderTagFunc;
    onLinkClicked: (resourceId: string | undefined, tagIds: string[] | undefined) => void;
    onTagLinkClicked: (tagId: string) => void;
    onSearchAllButtonClicked: (query: string) => void;
}

export default function GlobalSearchBar(props: GlobalSearchBarProps): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const searchManager = React.useContext(SearchContext);

    const open = Boolean(anchorEl);
    const id = open ? "search-results-popper" : undefined;

    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <CustomButton
                data-testid="global-search-bar"
                size="small"
                fullWidth
                aria-describedby={id}
                variant="outlined"
                onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
                startIcon={<Search />}
                endIcon={
                    searchManager.query && (
                        <CloseOutlined onClick={() => searchManager.setQuery("")} data-testid="clear-query-button" />
                    )
                }
            >
                <span className="fsr-btn-text">{searchManager.query}</span>
            </CustomButton>
            <Popover
                id={id}
                open={open}
                disableRestoreFocus
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                sx={PopoverStyle}
            >
                <GlobalSearchFlyout
                    onCloseClicked={() => setAnchorEl(null)}
                    onLinkClicked={props.onLinkClicked}
                    onTagLinkClicked={props.onTagLinkClicked}
                    onSearchAllButtonClicked={props.onSearchAllButtonClicked}
                    renderTags={props.renderTags}
                    hideOrgSelector={props.hideOrgSelector}
                />
            </Popover>
        </ThemeProvider>
    );
}
