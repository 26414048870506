import { useQuery } from "@tanstack/react-query";
import { ApiResponse, QueryHookOutput } from "./interfaces/DataHookOutput";
import fetchPaginated from "./utils/fetchPaginated";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { useAppSelector } from "../redux/hooks";
import { Group } from "../types/Group";
import { sortGroupBySequence } from "../utils/sortBySequence";

export default function useGroupsInOrganization(
    organizationId?: string,
    offset?: number,
    limit?: number
): QueryHookOutput<Group[]> {
    const token = useAppSelector((state) => state.auth.token);

    [offset, limit] = validateOffsetLimit(offset, limit);

    const path = `/organizations/${organizationId}/groups`;

    return useQuery({
        enabled: !!(organizationId && token),
        queryKey: [`groups-by-organization-${organizationId}-${offset}-${limit}`],
        queryFn: async () => {
            const results = await fetchPaginated<Group, ApiResponse<Group[]>>(path, token, offset, limit);
            return results.sort(sortGroupBySequence);
        },
    });
}
