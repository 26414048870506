import BuildingIcon from "@digital-hig/icon/lib/build/icons/miscellaneous/building";
import CheckMarkIcon from "@digital-hig/icon/lib/build/icons/utility/checkmark";
import { Icon, MenuItem, Typography } from "@mui/material";
import React from "react";
import { selectMenuItemIconCheckedStyle, selectMenuItemLogoStyle, selectMenuTextStyle } from "./stylesheet";
import { Organization } from "../../../types/Organization";

export interface SwitcherMenuItemProps {
    organization: Organization;
    selected?: Organization;
    handleMenuClicked: (org: string) => void;
}

export default function SwitcherMenuItem(props: SwitcherMenuItemProps): JSX.Element {
    const { organization, selected, handleMenuClicked } = props;
    const [showDefaultIcon, setShowDefaultIcon] = React.useState<boolean>(false);

    function renderOrganizationIcon(): JSX.Element {
        // If showing the default icon is desired, or if there is
        // no icon specified, then show the default building icon.
        //
        if (showDefaultIcon || !organization.userData?.iconUrl) {
            return (
                <Icon id="dhig--icons--miscellaneous--building" sx={selectMenuItemLogoStyle}>
                    <BuildingIcon />
                </Icon>
            );
        }

        // There's a valid 'userData.iconUrl' specified, if we are not able
        // to successfully load it, fallback to use the default icon.
        return (
            <Icon id="fsr-orgnization-logo" sx={selectMenuItemLogoStyle}>
                <img
                    src={organization.userData.iconUrl}
                    alt={organization.title}
                    onError={() => setShowDefaultIcon(true)}
                />
            </Icon>
        );
    }

    return (
        <MenuItem
            key={`org-${organization.name}`}
            data-testid={`org-${organization.name}`}
            value={organization.name}
            onClick={() => handleMenuClicked(organization.name)}
            sx={{ display: "flex", alignItems: "center" }}
        >
            {renderOrganizationIcon()}
            <Typography sx={selectMenuTextStyle}>{organization.title}</Typography>
            {selected && selected.name === organization.name && (
                <Icon id="dhig--icons--utility--checkmark" sx={selectMenuItemIconCheckedStyle}>
                    <CheckMarkIcon />
                </Icon>
            )}
        </MenuItem>
    );
}
