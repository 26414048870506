import { JsonSchema } from "./JsonSchema";
import i18n from "../i18n";

export enum SchemaType {
    propertySets = "propertySets",
    entities = "entities",
    hubs = "hubs",
    projects = "projects",
    folders = "folders",
    items = "items",
    versions = "versions",
    refs = "refs",
    links = "links",
    contents = "contents",
    customTypes = "customTypes",
    commands = "commands",
    assetTemplates = "assetTemplates",
    json = "json",
    gql = "gql",
    protobuf = "protobuf",
    auxiliary = "auxiliary",
    dependencies = "dependencies",
    derived = "derived",
    xrefs = "xrefs",
}

export const SchemaTypeName = {
    propertySets: i18n.t("schemaTypeName.propertySets"),
    entities: i18n.t("schemaTypeName.entities"),
    hubs: i18n.t("schemaTypeName.hubs"),
    projects: i18n.t("schemaTypeName.projects"),
    folders: i18n.t("schemaTypeName.folders"),
    items: i18n.t("schemaTypeName.items"),
    versions: i18n.t("schemaTypeName.versions"),
    refs: i18n.t("schemaTypeName.refs"),
    links: i18n.t("schemaTypeName.links"),
    contents: i18n.t("schemaTypeName.contents"),
    customTypes: i18n.t("schemaTypeName.customTypes"),
    commands: i18n.t("schemaTypeName.commands"),
    assetTemplates: i18n.t("schemaTypeName.assetTemplates"),
    json: i18n.t("schemaTypeName.json"),
    gql: i18n.t("schemaTypeName.gql"),
    protobuf: i18n.t("schemaTypeName.protobuf"),
    auxiliary: i18n.t("schemaTypeName.auxiliary"),
    dependencies: i18n.t("schemaTypeName.dependencies"),
    derived: i18n.t("schemaTypeName.derived"),
    xrefs: i18n.t("schemaTypeName.xrefs"),
    forgeDataSchema: i18n.t("extendedSchemaTypeName.forgeDataSchema"),
};

export interface SchemaRelationship {
    relationship: string;
    fromSnapshot: boolean | string;
    toAsset: string;
    toSnapshot: boolean | string;
    toCardinality: string;
}

export interface SchemaPagination {
    limit: number;
    offset: number;
    totalResults: number;
    hasNext: boolean;
}

/**
 * Component schema type definitions taken from `v1/ComponentMetaSchema.json`:
 *
 * https://git.autodesk.com/forge/forge-data-schema-validator/blob/master/src/main/resources/metaschema/v1/ComponentMetaSchema.json
 */

export type SchemaValues = Record<string, unknown> | string | number | boolean;

export interface SchemaAnnotation {
    description?: string; // Additional properties (beyond specs).
    relationships?: SchemaRelationship[]; // Additional properties (beyond specs).
}

export interface SchemaProperty {
    id: string;
    typeid: string;
    annotation?: Record<string, unknown>;
    context?: "single" | "map" | "array";
    optional?: boolean;
    dependsOn?: string[];
    length?: number;
}

export interface SchemaTypedValues {
    typeid?: string;
    value?: SchemaValues;
}

export interface SchemaConstant {
    id: string;
    typeid?: string;
    annotation?: Record<string, unknown>;
    value?: SchemaValues;
    typedValue?: SchemaTypedValues | SchemaTypedValues[];
}

export interface SchemaEnums {
    id: string;
    value: SchemaValues;
    annotation?: Record<string, unknown>;
}

export interface ComponentSchema {
    typeid: string;
    schemaSpecification?: string; // e.g. "forge-data-schema-2.0.0"
    schemaType?: "component" | "enum" | "entity";
    inherits?: string | string[];
    properties?: (SchemaProperty | SchemaEnums)[];
    constants?: SchemaConstant[];
    annotation?: SchemaAnnotation;
}

export interface GraphQLSchema {
    content: string;
}

export type SchemaObjectType = ComponentSchema | GraphQLSchema | JsonSchema;
