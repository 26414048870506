import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ProgressWrapperBox = styled(Box)({
    display: "flex",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    margin: "0 auto",
    backgroundColor: "transparent",
}) as typeof Box;

export const ProgressModalBox = styled(Box)({
    position: "fixed",
    width: "100vw",
    height: "100vh",
    left: 0,
    top: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 23,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
