import { ListItemSecondaryAction, ListItemText, styled, Typography } from "@mui/material";

export const CustomListItemText = styled(ListItemText)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "1.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "& >*": {
        fontFamily: "ArtifaktElement,sans-serif",
    },
    "& .MuiListItemText-primary": {
        display: "inline-block",
        fontSize: "14px",
        fontWeight: "700",
        color: "#212121",
        "& span": {
            backgroundColor: "#E0E0FF",
        },
    },
    "& .MuiListItemText-secondary": {
        display: "inline-block",
        fontSize: "12px",
        fontWeight: "400",
        marginLeft: "0.5rem",
        color: "#212121",
        "&::before": {
            display: "inline-block",
            content: `" \\00B7"`,
            width: "0.5rem",
        },
        "& span": {
            backgroundColor: "#E0E0FF",
        },
    },
});

export const CustomSecondaryAction = styled(ListItemSecondaryAction)({
    fontFamily: "ArtifaktElement,sans-serif",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    padding: "0.3rem 0.5rem",
    borderRadius: 40,
    "&:hover": {
        backgroundColor: "rgba(0,0,0,0.1)",
    },
});

export const TagCountText = styled(Typography)({
    fontFamily: "ArtifaktElement,sans-serif",
    fontSize: "10px",
    display: "inline-block",
    minWidth: "16px",
    textAlign: "right",
    color: "#212121",
});
