import { ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const wrapperBoxStyle = {
    width: "700px",
    minWidth: "100%",
    maxHeight: "80vh",
    padding: 0,
    margin: 0,
    overflowY: "auto",
};

export const CustomTextField = styled(TextField)({
    "& > *": {
        fontFamily: "ArtifaktElement,sans-serif",
    },
    "& > .MuiInput-root": {
        fontFamily: "ArtifaktElement,sans-serif",
        padding: "10px 1rem",
        "&:after": {
            borderColor: "#5F60FF",
        },
    },
    "& .MuiInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall": {
        padding: 0,
        fontSize: "14px",
    },
    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
        marginRight: "1rem",
    },
});

export const CustomListItemText = styled(ListItemText)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& >*": {
        fontFamily: "ArtifaktElement,sans-serif",
    },
    "& .MuiListItemText-primary": {
        display: "inline-block",
        fontSize: "14px",
        fontWeight: "700",
        color: "#212121",
    },
    "& .MuiListItemText-secondary": {
        display: "inline-block",
        fontSize: "14px",
        fontWeight: "400",
        marginLeft: "0.5rem",
        color: "#666666",
        "&::before": {
            display: "inline-block",
            content: `"-"`,
            width: "0.8rem",
        },
    },
});

export const CustomSubtleText = styled(Typography)({
    padding: "1rem 1.8rem",
    color: "#cccccc",
    fontSize: "14px",
    fontWeight: "400",
    "& > span": {
        textTransform: "capitalize",
        fontWeight: "700",
    },
});

export const CustomItemHeader = styled(ListItemButton)({
    backgroundColor: "#f5f5f5",
});

export const CustomItemFooter = styled(ListItemButton)({
    backgroundColor: "#fefefe",
    borderTop: "1px solid #f0f0f0",
});

export const CustomItemFooterText = styled(ListItemText)({
    paddingLeft: "1.8rem",
    color: "#cccccc",
    "& .MuiListItemText-primary": {
        fontSize: "12px",
    },
});
