import { ResourceId } from "./ResourceId";
import { SchemaType } from "./Schema";

export type SortType =
    | "nameAscending"
    | "nameDescending"
    | "createdAscending"
    | "createdDescending"
    | "modifiedAscending"
    | "modifiedDescending"
    | "relevance";

export type LocalizedStrings = { [lang: string]: string };

export interface SearchResultId {
    sourceSystem: "adsk.fssv3.autodesk";
    type: SchemaType;
    id: string;
    version: string;
    elementId: unknown;
    context: "fssv3";
    domain: "autodesk";
}

export interface SearchStaticProperties {
    organization: string;
    library: string;
    group: string;
    schemaType: SchemaType;
    qualifier: string;
    typeId: string;
    draft: boolean;

    inheritanceIds: string[];
    inheritanceNames: string[];

    attributeIds: unknown[];
    attributeNames: unknown[];
    attributeAlias: unknown[];

    componentIds: unknown[];
    componentNames: unknown[];
    componentAlias: unknown[];

    referenceIds: string[];
    referenceNames: string[];

    name: LocalizedStrings;
    description: LocalizedStrings;
    textContent: LocalizedStrings;

    sharedLocations: unknown[];
    specification: string;
    tags: unknown[];
    "constants.group": string;
    information: Record<string, unknown>;
}

export interface SearchQueryResult {
    searchResultId: SearchResultId;
    source: unknown;
    title: string;
    snippet: unknown;
    url: unknown;
    retrievedFields: Record<string, unknown>;
    creator: string;
    lastModifier: unknown;
    dateCreated: string;
    dateModified: string;
    dynamicPartition: unknown;
    positionHint: 1;
    viewerContexts: unknown[];
    content: {
        name: string;
        staticProperties: SearchStaticProperties;
    };
    payload: unknown;
}

export function getResourceIdFromSearchResult(result: SearchQueryResult): ResourceId | undefined {
    const resultId = result.searchResultId;
    if (!resultId || !resultId.id || !resultId.version || !resultId.type) {
        return undefined;
    }

    // The following code attempts to handle the inconsistencies between old
    // results (i.e. imported data) and new ones (i.e. newly created schemas)
    //
    let typeId = result.searchResultId.id;
    if (typeId.indexOf("-") < 0) {
        // Add version if it does not exist
        typeId = `${typeId}-${resultId.version}`;
    }

    return ResourceId.parse(`${resultId.type}:${typeId}`);
}

export function getLocalizedString(strings?: LocalizedStrings, lang = "enu"): string {
    if (!strings) {
        return "";
    }

    // Fallback to 'enu' if language not found.
    return strings[lang] ?? strings["enu"] ?? "";
}
