import { callGetApi } from "./apiCall";
import { TagResponse } from "../../types/Tag";
import { ApiResponse } from "../interfaces/DataHookOutput";

type ResponseTypes<DataType> = ApiResponse<DataType> | TagResponse<DataType>;

function extractData<DataType>(input: ResponseTypes<DataType[]>): DataType[] {
    return "data" in input ? input.data : input.results;
}

export default async function fetchPaginated<TData, TResponse extends ResponseTypes<TData[]>>(
    path: string,
    token: string,
    offset = 0,
    limit = 500,
    queryParams = new URLSearchParams()
): Promise<TData[]> {
    let allData: TData[] = [];

    const FETCH_LIMIT = 50;
    limit = limit < 500 ? limit : 500;

    while (limit > 0) {
        queryParams.set("offset", `${offset}`);
        queryParams.set("limit", `${limit > FETCH_LIMIT ? FETCH_LIMIT : limit}`);
        const response: TResponse = await callGetApi<TResponse>(`${path}?${queryParams}`, token);

        const data: TData[] = extractData<TData>(response);

        allData = [...allData, ...data];
        if (!response.pagination.hasNext || !data || data.length === 0) {
            break; // No more results
        }
        limit -= FETCH_LIMIT;
        offset += FETCH_LIMIT;
    }
    return allData;
}
