import React from "react";
import { useNavigate } from "react-router-dom";

import { StringVoidFunc } from "../../types/Functions";
import GlobalSearchResultTag from "../../ui-components/GlobalSearchResultTag/GlobalSearchResultTag";
import SearchResultsTag from "../../ui-components/SearchResultsTag/SearchResultsTag";

export interface CustomSearchResultsTagProps {
    resourceId: string;
    tagId: string;
    mode: "global" | "overview";
    onClick: StringVoidFunc;
}

export default function CustomSearchResultsTag(props: CustomSearchResultsTagProps): JSX.Element {
    const navigate = useNavigate();

    const tagInfo = props.tagId.split("||");
    if (tagInfo.length !== 3) {
        // Unexpected value, just return the tag as-is
        return <SearchResultsTag tagId={props.tagId} onTagLinkClicked={props.onClick} />;
    }

    const organizationId = tagInfo[0];
    const categoryId = tagInfo[1];
    const tagId = tagInfo[2];
    const displayTag = `${organizationId} / ${categoryId} / ${tagId}`.toUpperCase();

    const handleClick = () => {
        navigate(`/schema/${props.resourceId}`);
    };

    function renderTag(): JSX.Element {
        if (props.mode === "global") {
            return <GlobalSearchResultTag tagId={displayTag} onTagLinkClicked={handleClick} />;
        }

        return <SearchResultsTag tagId={displayTag} onTagLinkClicked={handleClick} />;
    }

    return renderTag();
}
