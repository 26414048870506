import Icon from "@digital-hig/icon";
import IconCaretDown from "@digital-hig/icon/lib/build/icons/utility/caret-down";
import IconCaretUp from "@digital-hig/icon/lib/build/icons/utility/caret-up";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ErrorMessageBox, WrapperBox } from "./stylesheet";
import { ActionSpecs } from "../../types/ActionSpecs";
import EmptyState from "../../ui-components/EmptyState/EmptyState";
import Layout from "../../ui-components/Layout";
import NavBar from "../../ui-components/Navbar/Navbar";
import Footer from "../Footer/Footer";

interface Error {
    stack?: string;
}

interface ErrorState {
    displayError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

interface IProps extends WithTranslation {
    children?: React.ReactNode;
}

/**
 * A simple component to catch Error Boundaries in its children
 *
 * WARNING: ErrorBoundary cannot use components that require hooks
 */
class ErrorBoundary extends React.Component<IProps, ErrorState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            displayError: false,
            error: null,
            errorInfo: null,
        };

        this.renderSecondaryAction = this.renderSecondaryAction.bind(this);
        this.handleSecondaryClicked = this.handleSecondaryClicked.bind(this);
    }

    componentDidCatch(error: unknown, errorInfo: React.ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        if (error instanceof Error) {
            this.setState({
                error: error,
                errorInfo: errorInfo,
            });
        }
    }

    handleSecondaryClicked(): void {
        this.setState({ displayError: !this.state.displayError });
    }

    renderSecondaryAction(): ActionSpecs {
        return {
            id: "secondary",
            text: this.props.t("buttons.learnMore"),
            variant: "text",
            endIcon: (
                <Icon id="dhig--icons--utility--caret-down" size="small">
                    {this.state.displayError ? <IconCaretUp /> : <IconCaretDown />}
                </Icon>
            ),
        };
    }

    render(): React.ReactNode {
        if (this.state.error) {
            // Custom fallback UI if any child crashed
            return (
                <Layout header={<NavBar />} footer={<Footer />}>
                    <WrapperBox id="error-boundary-wrapperbox" data-testid="error-boundary">
                        <EmptyState
                            body={this.props.t("alerts.reloadPageToContinue")}
                            primaryAction={this.props.t("buttons.reloadPage")}
                            secondaryAction={this.renderSecondaryAction()}
                            onPrimaryActionClicked={() => document.location.reload()}
                            onSecondaryActionClicked={() => this.handleSecondaryClicked()}
                        >
                            {this.state.displayError && this.state.errorInfo && (
                                <ErrorMessageBox>{this.state.errorInfo.componentStack}</ErrorMessageBox>
                            )}
                        </EmptyState>
                    </WrapperBox>
                </Layout>
            );
        }

        return <>{this.props.children}</>;
    }
}

export default withTranslation()(ErrorBoundary);
