import { ThemeProvider } from "@mui/material";
import React from "react";
import { CustomFormControl, CustomMenuItem, CustomSelect, CustomSelectInput } from "./stylesheet";
import { FsrMuiTheme } from "../../themes/FsrMuiTheme";
import { Organization } from "../../types/Organization";
export interface OrganizationSelectorProps {
    organizations: Organization[];
    organizationName?: string;
    onOrganizationChanged: (name: string) => void;
}

export default function OrganizationSelector(props: OrganizationSelectorProps): JSX.Element {
    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <CustomFormControl>
                <CustomSelect value={props.organizationName} input={<CustomSelectInput />}>
                    {props.organizations.map((org) => (
                        <CustomMenuItem
                            key={org.name}
                            value={org.name}
                            title={org.title}
                            onClick={() => props.onOrganizationChanged(org.name)}
                        >
                            {org.title}
                        </CustomMenuItem>
                    ))}
                </CustomSelect>
            </CustomFormControl>
        </ThemeProvider>
    );
}
