import { AppBar, Box, styled, Typography } from "@mui/material";

export const StyledAppBar = styled(AppBar)({
    background: "#0a0a0a",
    borderBottom: "1px solid #3d3d3d",
    zIndex: 2,
    "& .MuiToolbar-root": {
        minHeight: "56px",
    },
    "& .fsr-logo-image": {
        alignItems: "center",
        height: "30px",
        marginRight: "8px",
    },
}) as typeof AppBar;

export const ContentWrapperBox = styled(Box)({
    flex: 1,
    textAlign: "left",
}) as typeof Box;

export const LogoWrapperBox = styled(Box)({
    display: "flex",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "@media (max-width: 768px)": {
        flexDirection: "column",
        alignItems: "flex-start",
    },
}) as typeof Box;

export const TitleTypography = styled(Typography)({
    color: "#ffffff",
    paddingTop: "2px",
    fontSize: 16,
    lineHeight: "1.8",
    verticalAlign: "middle",
    "@media (max-width: 768px)": {
        fontSize: 12,
    },
}) as typeof Typography;
