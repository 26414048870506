/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Path } from "react-router-dom";
import AuthRequest from "../models/AuthRequest";
import { TokenData } from "../types/AuthResponse";

export function storeLocationState(state: any): void {
    if (state) {
        sessionStorage.setItem("fss-auth-redirect", JSON.stringify(state));
    } else {
        sessionStorage.removeItem("fss-auth-redirect");
    }
}

export function loadLocationState(): string | Partial<Path> {
    const redirectState = sessionStorage.getItem("fss-auth-redirect");
    if (redirectState) {
        const redirect = JSON.parse(redirectState);
        sessionStorage.removeItem("fss-auth-redirect");
        return { pathname: redirect.location, search: redirect.search };
    }

    return "/home"; // No stored location, simply navigate home.
}

export function generateCodeVerifier(): string {
    return Array(128)
        .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~")
        .map((x) => x[Math.floor((window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * x.length)])
        .join("");
}

export async function generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const buffer = await window.crypto.subtle.digest("SHA-256", data);

    const binary = new Uint8Array(buffer).reduce((data, byte) => {
        return data + String.fromCharCode(byte);
    }, "");

    return btoa(binary).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}

// A refresh token can only be used successfully once, if multiple calls are made,
// subsequent requests fail with "400 Bad Request". This hash-map ensures that no
// more than one token-refresh request is done.
//
const refreshRequests: Record<string, "pending"> = {};

export async function beginRefreshToken(refreshToken: string): Promise<false | TokenData> {
    if (refreshRequests[refreshToken] !== "pending") {
        try {
            refreshRequests[refreshToken] = "pending";
            const request = new AuthRequest();
            const tokenData = await request.refreshToken(refreshToken);
            delete refreshRequests[refreshToken];
            return tokenData;
        } catch (error) {
            delete refreshRequests[refreshToken];
        }
    }

    return false; // There's an existing refresh request
}
