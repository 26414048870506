import React from "react";
import { Cookies } from "react-cookie";
import LayoutSplitter from "./LayoutSplitter";
import { SplitterContentWrapper } from "./stylesheet";
import { SplitterSide } from "../../utils/SplitterMouseHandler";

interface SplitterContainerProps {
    id: string;
    side: SplitterSide;
    minWidth: number;
    defaultWidth: string;
    children: React.ReactNode;
}

export default function SplitterContainer(props: SplitterContainerProps): JSX.Element {
    const { id, side, minWidth, defaultWidth, children } = props;

    React.useEffect(() => {
        const element = document.getElementById(id);
        if (element) {
            const cookies = new Cookies();
            const storedWidth = cookies.get(`fsr-panel-width-${id}`);
            const width = storedWidth ? `${storedWidth}px` : defaultWidth;
            element.style.width = width;
            element.style.minWidth = width;
        }
    }, []);

    return (
        <SplitterContentWrapper id={id}>
            {children}
            <LayoutSplitter side={side} targetId={id} minWidth={minWidth} />
        </SplitterContentWrapper>
    );
}
