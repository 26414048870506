import React from "react";
import { SplitterBox } from "./stylesheet";
import { SplitterMouseHandler, SplitterSide } from "../../utils/SplitterMouseHandler";

interface LayoutSplitterProps {
    side: SplitterSide;
    minWidth: number;
    targetId: string;
}

// Splitter as a component by itself so that references are only created when needed
export default function LayoutSplitter(props: LayoutSplitterProps): JSX.Element {
    const { side, targetId, minWidth } = props;

    const splitterRef = React.useRef<HTMLDivElement | null>(null);
    const mouseHandler = React.useRef<SplitterMouseHandler | undefined>();

    React.useEffect(() => {
        if (splitterRef.current && !mouseHandler.current) {
            mouseHandler.current = new SplitterMouseHandler(splitterRef.current, side, minWidth, targetId);
        }
    }, [splitterRef.current, mouseHandler.current]);

    const cls = side === "left" ? "left-splitter" : "right-splitter";
    return <SplitterBox id={`${targetId}-splitter`} className={cls} component="div" ref={splitterRef} />;
}
