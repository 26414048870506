import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import { callGetApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { Library } from "../types/Library";

export default function useLibrary(
    organizationId?: string,
    groupId?: string,
    libraryId?: string
): QueryHookOutput<Library> {
    const token = useAppSelector((state) => state.auth.token);
    const path = `/organizations/${organizationId}/groups/${groupId}/libraries/${libraryId}`;

    return useQuery({
        retry: 0,
        enabled: !!(organizationId && groupId && libraryId && token),
        queryKey: [`library-${organizationId}-${groupId}-${libraryId}`],
        queryFn: () => callGetApi<Library>(path, token),
    });
}
