import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import { callGetApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { Group } from "../types/Group";

export default function useGroup(organizationId?: string, groupId?: string): QueryHookOutput<Group> {
    const token = useAppSelector((state) => state.auth.token);

    return useQuery({
        retry: 0,
        enabled: !!(organizationId && groupId && token),
        queryKey: [`group-${organizationId}-${groupId}`],
        queryFn: () => callGetApi<Group>(`/organizations/${organizationId}/groups/${groupId}`, token),
    });
}
