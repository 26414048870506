import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import { callGetApi } from "./utils/apiCall";
import { useAppSelector } from "../redux/hooks";
import { Organization } from "../types/Organization";

export default function useOrganization(organizationId?: string): QueryHookOutput<Organization> {
    const token = useAppSelector((state) => state.auth.token);

    return useQuery({
        enabled: !!(organizationId && token),
        queryKey: [`organization-${organizationId}`],
        queryFn: () => callGetApi<Organization>(`/organizations/${organizationId}`, token),
    });
}
