import Progress from "@digital-hig/progress";
import ThemeMui from "@digital-hig/theme-mui";
import React from "react";
import { ProgressModalBox, ProgressWrapperBox } from "./stylesheet";

export interface ProgressRingProps {
    label: string;
    modal?: boolean;
    size?: "small" | "medium" | "large";
}

export default function ProgressRing(props: ProgressRingProps): JSX.Element {
    const size = props.size ?? "small";
    const modal = props.modal ?? false;

    function renderProgress(): JSX.Element {
        return (
            <ThemeMui baseThemeName="dhig2021">
                <Progress type="indeterminate" label={props.label} size={size} />
            </ThemeMui>
        );
    }

    if (modal) {
        return <ProgressModalBox data-testid="modal-progress">{renderProgress()}</ProgressModalBox>;
    } else {
        return <ProgressWrapperBox data-testid="inline-progress">{renderProgress()}</ProgressWrapperBox>;
    }
}
