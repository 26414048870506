import dayjs, { Dayjs } from "dayjs";
import { SearchSchemaType } from "../../providers/SearchManagerTypes";

export type FilterType = {
    draft?: boolean;
    schemaType?: SearchSchemaType[];
    specification?: string[];
    organization?: string[];
    group?: string[];
    library?: string[];
    inheritanceIds?: string[];
    componentIds?: string[];
    before?: Dayjs;
    after?: Dayjs;
};

export class FilterBuilder {
    private _filter: FilterType;

    public constructor(filter: FilterType) {
        this._filter = { ...filter, draft: filter.draft ?? false };
    }

    private stringifyStringArray(key: string, value: string[]): string | undefined {
        if (value.length === 0) {
            return undefined;
        } else if (value.length === 1) {
            return `${key}:"${value[0]}"`;
        } else {
            return `(${value.map((v) => `${key}:"${v}"`).join(" OR ")})`;
        }
    }

    private stringify(
        key: string,
        value: string | string[] | Dayjs | boolean,
        sign: ">" | "<" | ":" = ":"
    ): string | undefined {
        if (Array.isArray(value)) {
            return this.stringifyStringArray(key, value);
        } else if (typeof value === "string") {
            return `${key}${sign}"${value}"`;
        } else if (typeof value === "boolean") {
            return `${key}${sign}${value}`;
        } else if (typeof value === "object" && value instanceof dayjs) {
            return `${key}${sign}"${value.toISOString()}"`;
        } else {
            throw new Error(`Invalid value type: ${typeof value}`);
        }
    }

    public build(): string {
        const filter = this._filter;
        const filterString = Object.keys(filter)
            .map((key) => {
                const value = filter[key as keyof FilterType];
                if (value === undefined) {
                    return undefined;
                }
                switch (key) {
                    case "schemaType":
                    case "organization":
                    case "group":
                    case "library":
                    case "inheritanceIds":
                    case "componentIds":
                    case "specification":
                        return this.stringify(key, value);
                    case "before":
                        return this.stringify("dateModified", value, "<");
                    case "after":
                        return this.stringify("dateModified", value, ">");
                    case "draft":
                        return this.stringify("draft", value);
                    default:
                        throw new Error(`Invalid filter key: ${key}`);
                }
            })
            .filter((value) => value !== undefined)
            .join(" AND ");
        return filterString;
    }

    public static merge(filters: FilterBuilder[]): string {
        if (filters.length === 0) {
            return "";
        } else if (filters.length === 1) {
            return filters[0].build();
        } else {
            return filters.map((filter) => `(${filter.build()})`).join(" OR ");
        }
    }
}
