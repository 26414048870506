import lightThemeData from "@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json";
import { createTheme } from "@mui/material";

const tokens = lightThemeData.tokens;
const primitives = lightThemeData.primitives;

// To override MUI defaults
export const FsrMuiTheme = createTheme({
    palette: {
        primary: {
            main: primitives.colors.accent.iris,
        },
        error: {
            main: primitives.colors.accent.clay,
        },
        success: {
            main: primitives.colors.accent.plant,
        },
    },
    typography: {
        fontFamily: primitives.fontFamilies.default,
    },
    // Override component styles
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none", // No drop shadow
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: primitives.fontFamilies.default,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    "&.MuiTooltip-popper[data-popper-placement*='right']": {
                        "& .MuiTooltip-tooltip": {
                            marginLeft: "17px",
                        },
                    },
                    "&.MuiTooltip-popper[data-popper-placement*='left']": {
                        "& .MuiTooltip-tooltip": {
                            marginRight: "4px",
                        },
                    },
                },
                tooltip: {
                    backgroundColor: "#666666",
                    borderRadius: "2px",
                    padding: "8px 12px",
                    "& p": {
                        fontSize: "12px",
                        color: "#fff",
                        maxWidth: 220,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    fontWeight: "600",
                    minWidth: "64px",
                    lineHeight: "1.25",
                    paddingBottom: "6.25px",
                    paddingLeft: "0.75rem",
                    paddingRight: "0.75rem",
                    paddingTop: "6.25px",
                    textTransform: "none",
                },
                contained: {
                    backgroundColor: "#000000",
                },
                containedError: {
                    backgroundColor: "#D74E26",
                    color: "white",
                    "& .MuiSvgIcon-root": {
                        color: "white",
                    },
                    "&:hover": {
                        backgroundColor: "#D74E26",
                        color: "white",
                    },
                },
                outlined: {
                    border: "1px solid #000000",
                    color: "#000000",
                    "&:hover": {
                        backgroundColor: "white",
                        color: "#000000",
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: "white !important",
                    height: "40px",
                    minHeight: "40px",
                    "& button.MuiTab-root": {
                        backgroundColor: "white",
                        textTransform: "capitalize",
                        minWidth: "50px",
                        "&.Mui-selected": {
                            fontWeight: "600",
                            color: "#000000",
                        },
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#000000",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: "2px",
                    color: primitives.colors.detail["slate-dark"],
                    "&:hover": {
                        color: primitives.colors.accent.iris,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...tokens.typography["short-copy-small"],
                    fontWeight: primitives.fontWeights.stout,
                    width: "fit-content",
                    padding: `${primitives.spacings["2"]} ${primitives.spacings["3"]}`,
                    backgroundColor: tokens.colors["detail-1-40"].rgb,
                    color: tokens.colors["ink-on-background"].rgb,
                    "&.Mui-selected": {
                        backgroundColor: tokens.colors["detail-2-opaque"].rgb,
                        color: tokens.colors["ink-on-fill-primary"].rgb,
                    },
                    ...(ownerState.variant === "filled" &&
                        ownerState.color === "error" && {
                            backgroundColor: tokens.colors.background.rgb,
                            color: tokens.colors["ink-error"].rgb,
                            border: `${primitives.borderWidths.medium} solid ${tokens.colors["ink-error"].rgb}`,
                            "&.Mui-selected": {
                                backgroundColor: tokens.colors["fill-error"].rgb,
                                color: tokens.colors["ink-error"].rgb,
                            },
                        }),
                    ...(ownerState.variant === "filled" &&
                        ownerState.color === "success" && {
                            backgroundColor: tokens.colors.background.rgb,
                            color: tokens.colors["ink-success"].rgb,
                            border: `${primitives.borderWidths.medium} solid ${tokens.colors["ink-success"].rgb}`,
                            "&.Mui-selected": {
                                backgroundColor: tokens.colors["fill-success"].rgb,
                                color: tokens.colors["ink-success"].rgb,
                            },
                        }),
                    ...(ownerState.variant === "filled" &&
                        ownerState.color === "warning" && {
                            backgroundColor: tokens.colors.background.rgb,
                            color: primitives.colors.accent.gold,
                            border: `${primitives.borderWidths.medium} solid ${primitives.colors.accent.gold}`,
                            "&.Mui-selected": {
                                backgroundColor: primitives.colors.accent.gold,
                                color: primitives.colors.accent.gold,
                            },
                        }),
                }),
                label: {
                    padding: primitives.spacings[0],
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "black",
                    "&.Mui-checked": {
                        color: "black",
                    },
                },
            },
        },
    },
});
