import { createContext } from "react";

type EnvironmentType = "prod" | "stg" | "dev" | "local";

export interface Environment {
    "CloudOS V3": "Frontend";
    REACT_APP_ENV: EnvironmentType;
    REACT_APP_BASE_PATH: ""; // TODO: Always an empty string, remove.
    REACT_APP_SCHEMA_BASEURL: string;
    REACT_APP_SCHEMA_BASEURL_V2: string;
    REACT_APP_SCHEMA_BASEURL_V3: string;
    REACT_APP_TAGCOLLECTION_BASEURL: string;
    REACT_APP_AUTH_BASEURL: string;
    REACT_APP_PROFILE_BASEURL: string;
    REACT_APP_CLIENT_ID: string;
    REACT_APP_AUTH_CALLBACK: string;
    REACT_APP_SCHEMA_CDN_V3: string;
    REACT_APP_SEARCH_BASEURL: string;
}

/**
 * For local development, the following values (e.g. process.env.REACT_APP_ENV)
 * will be populated by `.env` file (e.g. `.env.staging`). On an actual CloudOS
 * hosted site, the final `AppContext.env` value will be determined by fetching
 * the `/environment.json` file from the server. See `SchemaPortalApp` for more
 * information.
 */
const localEnvironment: Environment = {
    "CloudOS V3": "Frontend",
    REACT_APP_ENV: (process.env.REACT_APP_ENV as EnvironmentType) || "local",
    REACT_APP_BASE_PATH: "",
    REACT_APP_SCHEMA_BASEURL: process.env.REACT_APP_SCHEMA_BASEURL || "",
    REACT_APP_SCHEMA_BASEURL_V2: process.env.REACT_APP_SCHEMA_BASEURL_V2 || "",
    REACT_APP_SCHEMA_BASEURL_V3: process.env.REACT_APP_SCHEMA_BASEURL_V3 || "",
    REACT_APP_TAGCOLLECTION_BASEURL: process.env.REACT_APP_TAGCOLLECTION_BASEURL || "",
    REACT_APP_AUTH_BASEURL: process.env.REACT_APP_AUTH_BASEURL || "",
    REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "",
    REACT_APP_PROFILE_BASEURL: process.env.REACT_APP_PROFILE_BASEURL || "",
    REACT_APP_AUTH_CALLBACK: process.env.REACT_APP_AUTH_CALLBACK || "",
    REACT_APP_SCHEMA_CDN_V3: process.env.REACT_APP_SCHEMA_CDN_V3 || "",
    REACT_APP_SEARCH_BASEURL: process.env.REACT_APP_SEARCH_BASEURL || "",
};

/**
 * This 'appEnvironment' is meant for pure JavaScript classes which do not have
 * the ability to use 'React.useContext'. For example, 'BaseRequest' class needs
 * the environment to resolve request URLs, but cannot use 'useContext' API.
 * It is safe for such classes to access this variable because 'SchemaPortalApp'
 * makes sure that it is initialized to the right values before the rest of the
 * system gets created.
 *
 * All React components and hooks should access the environment through this call:
 *
 *      const appContext = React.useContext<AppContext>(ApplicationContext);
 *      const env = appContext.env; // Access the environment variables
 */
export let appEnvironment: Environment = localEnvironment;

export async function fetchEnvironment(): Promise<Environment> {
    if (process.env.REACT_APP_ENV === "local") {
        return localEnvironment;
    } else {
        const response = await fetch("/environment.json");
        const environmentJson = await response.json();
        appEnvironment = environmentJson;
        return environmentJson;
    }
}

export interface AppContext {
    env: Environment;
}

export const ApplicationContext = createContext<AppContext>({ env: localEnvironment });
