import { SchemaType } from "./Schema";

/**
 * The following are the parts of a given 'resourceId' string:
 *
 *      e.g. resourceId = "propertySets:autodesk.parameter.aec.enum:assets.design-6.0.0-beta.3"
 *
 *      | Resource ID                                                                               |
 *      | Schema Type  | Resource Name                                                              |
 *      | Extension ID                                                 | Version                    |
 *      | Entity ID                                    | Schema Name   | Version Core | Pre-Release |
 *      | Schema Type  | Qualifier                     | Schema Name   | Version Core | Pre-Release |
 *      | Schema Type  | Company  | Namespace          | Schema Name   | Version Core | Pre-Release |
 *      | ------------ | ----------------------------- | ------------- | -------------------------- |
 *      | propertySets | autodesk | parameter.aec.enum | assets.design |     6.0.0    |    beta.3   |
 *
 *
 * The following breakdown illustrates the parts of a 'resourceId':
 *
 *      resourceId = propertySets:autodesk.parameter.aec.enum:assets.design-6.0.0-beta.3
 *
 *      resourceId = extensionId + version
 *                 = ["propertySets:autodesk.parameter.aec.enum:assets.design", "6.0.0-beta.3"]
 *
 *      resourceId = extensionId + versionCore + PreRelease
 *                 = ["propertySets:autodesk.parameter.aec.enum:assets.design", "6.0.0", "beta.3"]
 *
 *      resourceId = entityId + schemaName + versionCore + PreRelease
 *                 = ["propertySets:autodesk.parameter.aec.enum", "assets.design", "6.0.0", "beta.3"]
 *
 *      resourceId = schemaType + qualifier + schemaName + versionCore + PreRelease
 *                 = ["propertySets", "autodesk.parameter.aec.enum", "assets.design", "6.0.0", "beta.3"]
 *
 *      resourceId = schemaType + company + namespace + schemaName + versionCore + PreRelease
 *                 = ["propertySets", "autodesk", "parameter.aec.enum", "assets.design", "6.0.0", "beta.3"]
 *
 *      resourceId = schemaType + resourceName
 *                 = ["propertySets", "autodesk.parameter.aec.enum:assets.design-6.0.0-beta.3"]
 *
 * Note that first part of 'schemaName' is referred to as 'categoryName'.
 * For example, the 'categoryName' of schema name 'assets.design' is 'assets'
 */

export class ExtensionId {
    private readonly _extensionId: string;
    private readonly _schemaType: SchemaType;
    private readonly _qualifier: string;
    private readonly _company: string;
    private readonly _namespace: string;
    private readonly _schemaName: string;
    private readonly _categoryName: string;

    constructor(extensionId: string) {
        // propertySets:autodesk.parameter.aec.enum:assets.design
        const parts = extensionId.split(":").filter((p) => !!p);

        if (parts.length !== 3) {
            throw `Invalid extension ID: '${extensionId}'`;
        }

        this._schemaType = parts[0] as SchemaType; // propertySets
        this._schemaName = parts[2]; // assets.design
        this._extensionId = extensionId;

        this._qualifier = parts[1]; // autodesk.parameter.aec.enum
        const firstPeriodIndex = this._qualifier.indexOf(".");
        if (firstPeriodIndex < 0) {
            throw `Invalid qualifier: '${this._qualifier}'`;
        }

        this._company = this._qualifier.substring(0, firstPeriodIndex); // autodesk
        this._namespace = this._qualifier.substring(firstPeriodIndex + 1); // parameter.aec.enum

        const nameParts = this._schemaName.split(".");
        this._categoryName = nameParts[0]; // assets
    }

    static parse(extensionId: string): ExtensionId | undefined {
        try {
            return new ExtensionId(extensionId);
        } catch (error) {
            return undefined;
        }
    }

    public get schemaType(): SchemaType {
        return this._schemaType;
    }

    public get qualifier(): string {
        return this._qualifier;
    }

    public get company(): string {
        return this._company;
    }

    public get namespace(): string {
        return this._namespace;
    }

    public get schemaName(): string {
        return this._schemaName;
    }

    public get categoryName(): string {
        return this._categoryName;
    }

    public get extensionId(): string {
        return this._extensionId;
    }
}

export class ResourceId extends ExtensionId {
    private readonly _resourceId: string;
    private readonly _version: string;
    private readonly _versionCore: string;
    private readonly _preRelease: string;

    constructor(resourceId: string) {
        const parts = resourceId.split("-").filter((p) => !!p);
        super(parts[0]);

        // Must be "extension-x.y.z" or "extension-x.y.z-beta.3"
        if (parts.length !== 2 && parts.length !== 3) {
            throw `Invalid resource ID: '${resourceId}'`;
        }

        this._versionCore = parts[1];
        this._preRelease = parts.length === 3 ? parts[2] : "";

        // Handle both "extension-x.y.z" or "extension-x.y.z-beta.3" scenarios
        this._version = resourceId.substring(resourceId.indexOf("-") + 1);
        this._resourceId = resourceId;
    }

    static parse(resourceId: string): ResourceId | undefined {
        try {
            return new ResourceId(resourceId);
        } catch (error) {
            return undefined;
        }
    }

    public get version(): string {
        return this._version;
    }

    public get versionCore(): string {
        return this._versionCore;
    }

    public get preRelease(): string {
        return this._preRelease;
    }

    public get resourceId(): string {
        return this._resourceId;
    }

    public get resourceName(): string {
        const firstColonIndex = this._resourceId.indexOf(":");
        return this._resourceId.substring(firstColonIndex + 1);
    }
}
