import { ThemeProvider } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomTagsBox } from "./stylesheet";
import { SearchContext } from "../../../providers/SearchManagerProvider";
import { FsrMuiTheme } from "../../../themes/FsrMuiTheme";
import { StringVoidFunc } from "../../../types/Functions";
import GlobalSearchBar from "../../../ui-components/GlobalSearchBar/GlobalSearchBar";
import CustomSearchResultsTag from "../../CustomSearchResultsTag/CustomSearchResultsTag";

export default function GlobalSearchWidget(): JSX.Element {
    const navigate = useNavigate();
    const searchManager = React.useContext(SearchContext);

    const handleSearchAllClicked = React.useCallback((): void => {
        window.open(`/search?query=${searchManager.query}`, "_blank");
    }, [searchManager.query]);

    const handleLinkClicked = (resourceId: string | undefined) => {
        if (resourceId) {
            navigate(`/schema/${resourceId}`);
        }
    };

    // Overrides the default tag rendering from SearchResultsViewer
    function overrideRenderTags(resourceId: string, tags: string[], onTagClicked: StringVoidFunc): JSX.Element {
        return (
            <CustomTagsBox>
                {tags.map((tag, i) => {
                    return (
                        <CustomSearchResultsTag
                            mode="global"
                            key={i}
                            resourceId={resourceId}
                            tagId={tag}
                            onClick={onTagClicked}
                        />
                    );
                })}
            </CustomTagsBox>
        );
    }

    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <GlobalSearchBar
                onLinkClicked={handleLinkClicked}
                onTagLinkClicked={() => null}
                onSearchAllButtonClicked={handleSearchAllClicked}
                renderTags={overrideRenderTags}
                hideOrgSelector={true}
            />
        </ThemeProvider>
    );
}
