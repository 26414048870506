import React from "react";
import { Action } from "../types/ActionSpecs";

export type AlertSeverity = "success" | "info" | "warning" | "error";

export interface AlertContent {
    severity: AlertSeverity;
    message: string;
    autoHideMs?: number; // 0 to disable auto-hide
    actions?: Action[];
    onActionClicked?: (id: string) => void;
}

export interface AlertState {
    content?: AlertContent;
}

export interface AlertAction {
    action: "alert" | "clear";
    content?: AlertContent;
}

const initialAlert: AlertState = { content: undefined };

export const AlertContext = React.createContext<AlertState>(initialAlert);
export const AlertDispatchContext = React.createContext<React.Dispatch<AlertAction>>(() => {
    // Use an empty function here to initialize 'AlertDispatchContext' so it
    // will never have to take a 'null', otherwise all consumers of 'dispatch'
    // will have to do a null-check.
});

export interface AlertProviderProps {
    children: React.ReactNode;
}

export function AlertProvider(props: AlertProviderProps): JSX.Element {
    const [alert, dispatch] = React.useReducer(alertReducer, initialAlert);

    return (
        <AlertContext.Provider value={alert}>
            <AlertDispatchContext.Provider value={dispatch}>{props.children}</AlertDispatchContext.Provider>
        </AlertContext.Provider>
    );
}

function alertReducer(alert: AlertState, action: AlertAction): AlertState {
    switch (action.action) {
        case "alert": {
            return { ...alert, content: action.content as AlertContent };
        }
        case "clear": {
            return { ...alert, content: undefined };
        }
        default: {
            throw Error("Unknown action: " + action.action);
        }
    }
}
