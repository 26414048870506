import { Box, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import {
    LayoutAlertBanner,
    LayoutContentBreadcrumb,
    LayoutContentFooter,
    LayoutContentLeft,
    LayoutContentLeftWrapper,
    LayoutContentMain,
    LayoutContentMainWrapper,
    LayoutContentMiddle,
    LayoutContentRight,
    LayoutContentRightScrollable,
    LayoutContentToolbar,
    LayoutContentWrapper,
    LayoutNavbar,
    LayoutWrapper,
    NoSupportWrapper,
} from "./stylesheet";

import { FsrMuiTheme } from "../../themes/FsrMuiTheme";
import SplitterContainer from "../SplitterContainer/SplitterContainer";

export interface LayoutProps {
    alertBanner?: React.ReactNode;
    header?: React.ReactNode;
    left?: React.ReactNode;
    right?: React.ReactNode;
    toolbar?: React.ReactNode;
    breadcrumb?: React.ReactNode;
    footer?: React.ReactNode;
    hideMainFooter?: boolean;
    children?: React.ReactNode; // Middle section
}

export default function Layout(props: LayoutProps): JSX.Element {
    const { header, alertBanner: banner, footer, left, right, toolbar } = props;

    function renderNavbar(): JSX.Element | null {
        return header ? <LayoutNavbar id="fsr-layout-navbar">{header}</LayoutNavbar> : null;
    }

    function renderAlertBanner(): JSX.Element | null {
        return banner ? <LayoutAlertBanner id="fsr-layout-alert-banner">{banner}</LayoutAlertBanner> : null;
    }

    function renderLeftPanel(): JSX.Element | null {
        if (!left) {
            return null;
        }

        return (
            <SplitterContainer id="fsr-layout-content-left-wrapper" side="right" minWidth={240} defaultWidth="20vw">
                <LayoutContentLeftWrapper>
                    <LayoutContentLeft id="fsr-layout-content-left">{left}</LayoutContentLeft>
                    {footer && <Box id="fsr-layout-content-footer">{footer}</Box>}
                </LayoutContentLeftWrapper>
            </SplitterContainer>
        );
    }

    function renderRightPanel(): JSX.Element | null {
        if (!right) {
            return null; // Do not render 'LayoutContentRight' at all
        }

        let rightContent = (
            <LayoutContentRight id="fsr-layout-content-right">
                {right && <LayoutContentRightScrollable>{right}</LayoutContentRightScrollable>}
            </LayoutContentRight>
        );

        // Splitter is only needed with both middle/right panels are visible
        if (props.children && right) {
            rightContent = (
                <SplitterContainer id="fsr-layout-content-right-wrapper" side="left" minWidth={400} defaultWidth="40vw">
                    {rightContent}
                </SplitterContainer>
            );
        }

        return rightContent;
    }

    function renderToolbar(): JSX.Element | null {
        if (!toolbar) {
            return null;
        }

        return <LayoutContentToolbar id="fsr-layout-content-toolbar">{toolbar}</LayoutContentToolbar>;
    }

    function renderBreadcrumb(): JSX.Element | null {
        return props.breadcrumb ? (
            <LayoutContentBreadcrumb id="fsr-layout-content-breadcrumb">{props.breadcrumb}</LayoutContentBreadcrumb>
        ) : null;
    }

    function renderFooter(): JSX.Element | null {
        return footer ? <LayoutContentFooter id="fsr-layout-content-footer">{footer}</LayoutContentFooter> : null;
    }

    function renderMainPanel(): JSX.Element {
        const classNames: string[] = [];
        if (!left && !right) {
            // Allow overflow-y for single column layout
            classNames.push("fsr-layout-single-column");
        }

        return (
            <LayoutContentMainWrapper id="fsr-layout-content-main-wrapper">
                {renderBreadcrumb()}
                <LayoutContentMain id="fsr-layout-content-main">
                    <LayoutContentMiddle id="fsr-layout-content-middle" className={classNames.join(" ")}>
                        {props.children}
                        {renderRightPanel()}
                        {renderToolbar()}
                        {!props.left && !props.hideMainFooter && renderFooter()}
                    </LayoutContentMiddle>
                </LayoutContentMain>
            </LayoutContentMainWrapper>
        );
    }

    return (
        <ThemeProvider theme={FsrMuiTheme}>
            <LayoutWrapper id="fsr-layout-wrapper">
                {renderNavbar()}
                {renderAlertBanner()}
                <LayoutContentWrapper id="fsr-layout-content-wrapper">
                    {renderLeftPanel()}
                    {renderMainPanel()}
                </LayoutContentWrapper>
                <NoSupportWrapper>
                    <Typography>
                        Sorry, mobile screen (portrait mode) is currently not supported. Please view this on a screen of
                        more than 768px in width.
                    </Typography>
                </NoSupportWrapper>
            </LayoutWrapper>
        </ThemeProvider>
    );
}
