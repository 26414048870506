import Box from "@mui/material/Box";
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthMiddleware, QualtricsMiddleware } from "./middleware";
import ProgressRing from "./ui-components/ProgressRing/ProgressRing";
import AuthHandler from "./views/Auth/AuthHandler";
import Landing from "./views/Public/Landing";

const Home = React.lazy(() => import("./views/Home/Home"));
const OrganizationView = React.lazy(() => import("./views/OrganizationView/OrganizationView"));
const GroupView = React.lazy(() => import("./views/GroupView/GroupView"));
const EditorView = React.lazy(() => import("./views/EditorView/EditorView"));
const SearchView = React.lazy(() => import("./views/Search/SearchView"));
const StandaloneView = React.lazy(() => import("./views/StandaloneView/StandaloneView"));
const NotFound = React.lazy(() => import("./views/Public/NotFound"));
const CreateGroupView = React.lazy(() => import("./views/CreateGroupView/CreateGroupView"));

/**
 * This is a full-screen component meant as a placeholder element before
 * lazy-loaded components are fully downloaded for display. After a
 * lazy-loaded component is downloaded, the full-screen placeholder will
 * not be shown anymore when the same lazy-loaded component is requested.
 */
function FullScreenProgress(): JSX.Element {
    return (
        <Box sx={{ width: "100vw", height: "100vh" }}>
            <ProgressRing label="Loading..." />
        </Box>
    );
}

export default function SchemaRouter(): JSX.Element {
    return (
        <Router>
            <Suspense fallback={<FullScreenProgress />}>
                <Routes>
                    <Route path="/" element={<QualtricsMiddleware />}>
                        <Route index element={<Landing />} />
                        <Route path="oauth/:action" element={<AuthHandler />} />
                        <Route element={<AuthMiddleware />}>
                            <Route path="home" element={<Home />} />
                            <Route path="organization/:organizationId" element={<OrganizationView />} />
                            <Route
                                path="organization/:organizationId/create-group"
                                element={<CreateGroupView />}
                            ></Route>
                            <Route path="organization/:organizationId/group/:groupId/members" element={<GroupView />} />
                            <Route
                                path="organization/:organizationId/group/:groupId/library/:libraryId/members"
                                element={<GroupView />}
                            />
                            <Route path="organization/:organizationId/group/:groupId" element={<GroupView />}>
                                <Route path="library/:libraryId" element={<GroupView />}>
                                    <Route path="schema/:schemaId" element={<GroupView />} />
                                </Route>
                            </Route>
                            <Route
                                path="organization/:organizationId/group/:groupId/library/:libraryId/edit"
                                element={<EditorView />}
                            />
                            <Route
                                path="organization/:organizationId/group/:groupId/library/:libraryId/edit/:schemaId"
                                element={<EditorView />}
                            />
                            <Route path="search" element={<SearchView />} />
                        </Route>
                        <Route path="schema/:schemaId" element={<StandaloneView />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    );
}
