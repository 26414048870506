import lightThemeData from "@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json";
import { Box, styled, Typography } from "@mui/material";
const tokens = lightThemeData.tokens;

export const ContentWrapperBox = styled(Box)({
    textAlign: "left",
    padding: "2em",
    maxWidth: "550px",
}) as typeof Box;

export const EmptyStateIconBox = styled(Box)({
    marginBottom: "32px",
}) as typeof Box;

export const TitleTypography = styled(Typography)({
    fontSize: "21px",
    fontWeight: "700",
    lineHeight: "26px",
    marginBottom: "4px",
}) as typeof Typography;

export const BodyTypography = styled(Typography)({
    ...tokens.colors["text-on-background"],
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
}) as typeof Typography;

export const ActionButtonBox = styled(Box)({
    width: "fit-content",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    marginTop: "32px",
}) as typeof Box;
