import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useGlobalAlert, { AlertOptions } from "../hooks/useGlobalAlert";
import AuthRequest from "../models/AuthRequest";
import { refreshToken as setRefreshToken } from "../redux/authSlice";
import { useAppSelector } from "../redux/hooks";

const FIVE_MINS_IN_MS = 5 * 60 * 1000;

export default function AuthMiddleware(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const alerts = useGlobalAlert();
    const { t } = useTranslation();

    const tokenTimerRef = React.useRef<number>(0);

    const isAuthenticated = useAppSelector((state) => state.auth.isLogin);
    const tokenTimeMs = useAppSelector((state) => state.auth.tokenTimeMs);
    const expiresInMs = useAppSelector((state) => state.auth.expiresInMs);
    const refreshToken = useAppSelector((state) => state.auth.refreshToken);

    function installRefreshTimer(_refreshToken: string, expiresInMs: number, tokenTimeMs: number): void {
        if (!tokenTimerRef.current) {
            const elapsedMs = Date.now() - tokenTimeMs;
            let duration = expiresInMs - elapsedMs - FIVE_MINS_IN_MS; // 5 minutes prior to expiry
            duration = duration > 0 ? duration : 10; // When browser wakes up after long sleep
            tokenTimerRef.current = window.setTimeout(() => beginRefreshToken(_refreshToken), duration);
        }
    }

    function clearRefreshTimer(): void {
        if (tokenTimerRef.current) {
            clearTimeout(tokenTimerRef.current); // Remove existing token timer
            tokenTimerRef.current = 0;
        }
    }

    async function beginRefreshToken(_refresh_token: string): Promise<void> {
        const loginAlertOptions: AlertOptions = {
            actions: [{ id: "login", text: t("buttons.signIn"), variant: "outlined" }],
            onActionClicked: () => {
                // Remove query string from URL before redirect
                window.history.replaceState({}, "", `/`);
                navigate("/oauth/login", { replace: true });
            },
        };

        try {
            clearRefreshTimer();

            const auth = new AuthRequest();
            const result = await auth.refreshToken(_refresh_token);
            if (result.access_token) {
                dispatch(setRefreshToken(result));
            } else {
                alerts.error(t("alerts.oauthCannotRefreshCode"), loginAlertOptions);
            }
        } catch (e) {
            console.error(JSON.stringify(e, undefined, 4));
            alerts.error(t("alerts.oauthCannotRefreshCode"), loginAlertOptions);
        }
    }

    React.useEffect(() => {
        if (!isAuthenticated) {
            // If not authenticated, redirect to verify
            const navState = { location: location.pathname, search: location.search };
            navigate("/oauth/verify", { state: navState });
        }
    }, [isAuthenticated]);

    React.useEffect(() => {
        if (isAuthenticated) {
            installRefreshTimer(refreshToken, expiresInMs, tokenTimeMs);
        }

        // Clear timer, in case page switch before completion
        return () => clearRefreshTimer();
    }, [isAuthenticated, refreshToken, tokenTimeMs]);

    // When 'AuthMiddleware' renders, check to see if the current token has
    // expired. If it gets within five minutes of expiry, the refresh happens.
    //
    if (isAuthenticated) {
        if (Date.now() - tokenTimeMs + FIVE_MINS_IN_MS > expiresInMs) {
            beginRefreshToken(refreshToken);
        }
    }

    return isAuthenticated ? <Outlet /> : <></>;
}
