import Button from "@digital-hig/button";
import ThemeMui from "@digital-hig/theme-mui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
    BannerBodyTypography,
    BannerTitleTypography,
    ContainerBox,
    ImageWrapperBox,
    LeftColumnBox,
    RightColumnBox,
    WrapperBox,
} from "./stylesheet";

import imageBase1 from "../../assets/sp_landing_base1.png";
import imageBase2 from "../../assets/sp_landing_base2.png";
import imageBase3 from "../../assets/sp_landing_base3.png";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navigation/NavBar";
import Layout from "../../ui-components/Layout";

export default function Landing(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [image1, setImage1] = React.useState<boolean>(false);
    const [image2, setImage2] = React.useState<boolean>(false);
    const [image3, setImage3] = React.useState<boolean>(false);

    React.useEffect(() => {
        document.title = t("app.title"); // Set brower title
        const timer1 = setTimeout(() => setImage1(true), 600);
        const timer2 = setTimeout(() => setImage2(true), 1300);
        const timer3 = setTimeout(() => setImage3(true), 2000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        };
    }, []);

    function renderContent(): JSX.Element {
        return (
            <WrapperBox>
                <ContainerBox>
                    <LeftColumnBox>
                        <BannerTitleTypography>{t("landing.title")}</BannerTitleTypography>
                        <BannerBodyTypography>{t("landing.description")}</BannerBodyTypography>
                        <ThemeMui baseThemeName="dhig2021Dark">
                            <Button
                                onClick={() => navigate("/home")}
                                className="fss-landing-btn"
                                data-testid="fss-landing-btn"
                            >
                                Start exploring
                            </Button>
                        </ThemeMui>
                    </LeftColumnBox>
                    <RightColumnBox>
                        <ImageWrapperBox>
                            <img src={imageBase1} className={`fss-image-base${image1 ? " fss-image-base-1" : ""}`} />
                            <img src={imageBase2} className={`fss-image-base${image2 ? " fss-image-base-2" : ""}`} />
                            <img src={imageBase3} className={`fss-image-base${image3 ? " fss-image-base-3" : ""}`} />
                        </ImageWrapperBox>
                    </RightColumnBox>
                </ContainerBox>
            </WrapperBox>
        );
    }

    return (
        <Layout header={<NavBar />} footer={<Footer />}>
            {renderContent()}
        </Layout>
    );
}
