import { appEnvironment, Environment } from "../ApplicationContext";
import { ErrorMessage } from "../types/ErrorResponse";
import fetchCached from "../utils/fetchCached";

export default abstract class BaseRequest<ResType> {
    cacheResponse: boolean;

    constructor() {
        this.cacheResponse = true;
    }

    public async invoke(accessToken?: string): Promise<ResType> {
        const basePath = this.getBasePathCore(appEnvironment);

        const headers = { "Content-Type": "application/json" };
        if (accessToken) {
            Object.assign(headers, { Authorization: `Bearer ${accessToken}` });
        }

        // Get possible header alternatives from derived classes
        Object.assign(headers, this.getHeadersCore());

        // Get possible options for the request
        const options = this.getOptionsCore();
        // Add headers to options
        Object.assign(options, { headers: headers });

        // Get request specific path from derived classes
        const path = this.getPathCore();
        const url = `${basePath}/${path}`;

        const response = await fetchCached(url, options, this.cacheResponse);

        if (!response.ok) {
            // Allow derived classes to format and throw the error first
            await this.handleFailedRequest(response);

            // Derived class does not throw, throw the error here
            const message = await this._extractErrorMessage(response);
            const context = this.getRequestContextCore();
            throw context ? `${message} (${context})` : message;
        }

        return this.extractResultCore(response);
    }

    protected getBasePathCore(env: Environment): string {
        return env.REACT_APP_SCHEMA_BASEURL_V3;
    }

    protected getHeadersCore(): Record<string, string> {
        return {};
    }

    protected getOptionsCore(): Record<string, string> {
        return {};
    }

    /**
     * Derived class returns a valid string (the request context) that carries more
     * information of the current request. This will be used to annotate the error
     * message that is thrown, allowing the caller to display more informative error
     * message to the user. For example, 'GetLibraryByNameRequest' returns the
     * following string:
     *
     *      "Get library: autodesk/beverages/juices"
     *
     * This allows the 'BaseRequest' to throw this exception:
     *
     *      "User or Client is not authorized for this operation. (Get library: autodesk/beverages/juices)"
     *
     * Instead of just this generic message:
     *
     *      "User or Client is not authorized for this operation."
     */
    protected getRequestContextCore(): string | undefined {
        return undefined;
    }

    protected handleFailedRequest(response: Response): Promise<void> {
        response;
        return Promise.resolve();
    }

    // "Core" functions to be defined by derived classes.
    protected abstract getPathCore(): string;
    protected abstract extractResultCore(response: Response): Promise<ResType>;

    private async _extractErrorMessage(response: Response): Promise<string> {
        try {
            // Extract well-formed error detail if one exists.
            const errors = await response.json();
            if (errors) {
                if (typeof errors.developerMessage === "string") {
                    return errors.developerMessage;
                } else if (Array.isArray(errors.errors)) {
                    const details = errors.errors.map((e: ErrorMessage) =>
                        typeof e.detail === "object" ? JSON.stringify(e.detail) : String(e.detail)
                    );

                    return details.join(" "); // Join multiple messages
                }
            }
        } catch (error) {
            // When it gets here it means `response.json()` call has failed
            // (i.e. we are dealing with a non-JSON response body). In that
            // case the error should just be left as 'status-statusText').
            // The thrown 'error' here will not be meaningful so it will be
            // discarded instead of returning to the caller.
            error;
        }

        // Default error message is in the form of "status-statusText".
        return `${response.status} ${response.statusText}`;
    }
}
