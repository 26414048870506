import lightThemeData from "@digital-hig/theme-data/lib/build/json/lightTheme/nestedRoles.json";
import { Box, styled, Typography } from "@mui/material";

const primitives = lightThemeData.primitives;

export const WrapperBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#0A0A0A",
    paddingTop: 50,
    paddingBottom: 50,
    "@media (max-width: 768px)": {
        paddingTop: 20,
        paddingBottom: 20,
    },
}) as typeof Box;

export const NotFoundWrapperBox = styled(Box)({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}) as typeof Box;

export const ContainerBox = styled(Box)({
    display: "flex",
    flex: 1,
    maxWidth: 1024,
    margin: "0 auto",
    gap: 40,
    backgroundColor: "transparent",
    "@media (max-width: 768px)": {
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
    },
}) as typeof Box;

export const LeftColumnBox = styled(Box)({
    flex: 12,
    paddingTop: 50,
    "@media (max-width: 768px)": {
        flex: 0,
    },
}) as typeof Box;

export const RightColumnBox = styled(Box)({
    flex: 12,
    "@media (max-width: 768px)": {
        width: "90%",
    },
}) as typeof Box;

export const ImageWrapperBox = styled(Box)({
    position: "relative",
    height: "100%",
    width: "100%",
    maxHeight: "40vw",
    maxWidth: "40vw",
    "&>.fss-image-base": {
        position: "absolute",
        display: "block",
        opacity: 0,
        transform: "translateY(-100px)",
        transition: "opacity 2s, transform 2s",
    },
    "&>.fss-image-base-1": {
        opacity: 1,
        top: "45%",
        left: 0,
        width: "98%",
        transform: "translateY(0px)",
    },
    "&>.fss-image-base-2": {
        opacity: 1,
        top: "20%",
        left: "10%",
        width: "80%",
        transform: "translateY(0px)",
    },
    "&>.fss-image-base-3": {
        opacity: 1,
        top: 0,
        left: "10%",
        width: "80%",
        transform: "translateY(0px)",
    },
    "@media (max-width: 768px)": {
        maxHeight: "454px",
        maxWidth: "80vw",
        height: "100vw",
        overflow: "visible",
        margin: "0 auto",
        padding: 0,
    },
}) as typeof Box;

export const BannerTitleTypography = styled(Typography)({
    fontFamily: primitives.fontFamilies.default,
    color: "#ffffff",
    marginBottom: primitives.spacings[7],
    fontSize: 34,
    fontWeight: "600",
    lineHeight: 1.2,
    "@media (max-width: 768px)": {
        fontSize: 24,
    },
}) as typeof Typography;

export const BannerBodyTypography = styled(Typography)({
    fontFamily: primitives.fontFamilies.default,
    color: "#ffffff",
    marginBottom: primitives.spacings[7],
    fontSize: primitives.fontSizes[2],
    lineHeight: 1.4,
}) as typeof Typography;
