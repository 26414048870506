import { AppBar, Box, Container, Toolbar } from "@mui/material";
import React from "react";
import OrganizationSwitcher from "./OrganizationSwitcher/OrganizationSwitcher";
import { secondaryNavStyle } from "./stylesheet";
import { useOrganizations } from "../../hooks";

export default function SecondaryNavBar(): JSX.Element | null {
    const { data: organizations, isLoading } = useOrganizations();

    if (isLoading || !organizations || organizations?.length <= 1) {
        return null;
    }

    return (
        <AppBar position="static" sx={secondaryNavStyle}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Box sx={{ minWidth: 120 }}>
                        <OrganizationSwitcher />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
