import { useQuery } from "@tanstack/react-query";
import { QueryHookOutput } from "./interfaces/DataHookOutput";
import fetchPaginated from "./utils/fetchPaginated";
import validateOffsetLimit from "./utils/validateOffsetLimit";
import { useAppSelector } from "../redux/hooks";
import { ResourceId } from "../types/ResourceId";
import { SchemaVersionsData } from "../types/SchemaVersion";
import { TagResponse } from "../types/Tag";
import { TagResource } from "../types/TagResource";

export default function useTaggedSchemasInGroup(
    groupId?: string,
    tagIds?: string,
    scope?: string, // e.g. <group> or <group>.<library> for GroupFilters
    offset?: number,
    limit?: number
): QueryHookOutput<SchemaVersionsData[]> {
    const token = useAppSelector((state) => state.auth.token);

    tagIds = tagIds ?? "";
    scope = scope ?? groupId ?? "";
    [offset, limit] = validateOffsetLimit(offset, limit);
    const queryParams = new URLSearchParams({ tagIds, scope });
    const path = `/tag-collections/${groupId}/resources`;

    return useQuery({
        enabled: !!(groupId && tagIds && token),
        queryKey: [`tags-${groupId}-${tagIds}`],
        queryFn: async () => {
            const response = await fetchPaginated<TagResource, TagResponse<TagResource[]>>(
                path,
                token,
                offset,
                limit,
                queryParams
            );

            const taggedSchemas = response.map((taggedResource) => {
                const resource = new ResourceId(taggedResource.resourceId);
                return {
                    id: resource.resourceId,
                    name: resource.schemaName,
                    qualifier: resource.qualifier,
                    schemaType: resource.schemaType,
                    semanticVersion: resource.version,
                    status: "published", // Assuming it's published
                    title: "",
                    description: "",
                    createdBy: "",
                    createTime: "",
                };
            });

            return taggedSchemas;
        },
    });
}
